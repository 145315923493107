import React from 'react';
import {
  Box,
  InputLabel,
  TextField,
  Autocomplete,
  Paper,
  Theme,
  FilterOptionsState
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { labelStyle } from '../SigninPage';
import { IErrors } from './SignupPageNew';
import { countries, CountryType } from '../../../utils/countries';
import { ILoginErrors } from '../SigninNew/SigninPageNew';
import { inputPropsStyle, getBorderColor } from '../../../utils/ui';

const filterOptions = (options: CountryType[], state: FilterOptionsState<CountryType>) => {
  let searchValue = state.inputValue;
  if (searchValue === '') {
    return options;
  } else if (searchValue.length > 0 && searchValue[0] === '+') {
    searchValue = state.inputValue.substring(1);
  }
  const result = options.filter(
    (opt) =>
      opt.label.toLowerCase().indexOf(searchValue.toLowerCase()) === 0 ||
      opt.phone.indexOf(searchValue) === 0
  );
  return result;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} style={{ width: 300 }} />;
};

interface IProps {
  formErrors: IErrors | ILoginErrors;
  phone: string;
  handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  phoneCountry: CountryType | null;
  handlePhoneCodeChange: (_: unknown, value: CountryType | null) => void;
}

const PhoneForm: React.FunctionComponent<IProps> = ({
  formErrors,
  phone,
  handlePhoneChange,
  handlePhoneCodeChange,
  phoneCountry
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <InputLabel htmlFor="phone" style={labelStyle}>
        {t('account.phoneLabel')}
      </InputLabel>
      <TextField
        id="phone"
        name="phone"
        margin="normal"
        variant="outlined"
        fullWidth
        required
        value={phone}
        onChange={handlePhoneChange}
        error={formErrors.phoneCode !== null}
        helperText={formErrors.phoneCode ? formErrors.phoneCode : ''}
        sx={{
          height: '46px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: getBorderColor(phone)
            }
          }
        }}
        inputProps={{ sx: inputPropsStyle }}
        placeholder={t('account.phonePlaceHolder') as string}
        color="info"
        InputProps={{
          startAdornment: (
            <Autocomplete
              id="phoneCodeAutocomplate"
              filterOptions={filterOptions}
              style={{
                position: 'relative',
                height: 30,
                top: '-24px',
                left: '-14px',
                width: '43%'
              }}
              disableClearable
              PaperComponent={CustomPaper}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              value={phoneCountry}
              onChange={handlePhoneCodeChange}
              options={countries}
              getOptionLabel={(option) => `+${option.phone}`}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: '46px',
                    '& > img': { mr: 2, flexShrink: 0 },
                    '& .MuiInputBase - input': {
                      height: '46px'
                    }
                  }}
                  {...props}
                  key={option.label}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt="countryFlag"
                  />
                  {option.label} +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="phoneCodeInput"
                  name="phoneCodeInput"
                  margin="normal"
                  variant="standard"
                  error={formErrors.phoneCode !== null}
                  helperText={formErrors.phoneCode ? formErrors.phoneCode : ''}
                  sx={{
                    borderRight: (t) => `1px solid ${t.palette.grey[50]}`,
                    height: '46px',
                    '& .MuiOutlinedInput-root': {
                      height: '46px',
                      padding: '0 !important',
                      '& fieldset': {
                        // borderColor: getBorderColor(country?.label)
                      }
                    }
                  }}
                  color="info"
                  inputProps={{
                    ...params.inputProps,
                    sx: {
                      ...inputPropsStyle,
                      textAlign: 'right',
                      marginRight: '3%',
                      color: (t: Theme) => t.palette.info.main
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                />
              )}
            />
          )
        }}
      />
    </Box>
  );
};

export default PhoneForm;
