import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
//* MUI
import { Box, Fade, Grow } from '@mui/material';
//* Enums
import { Actions } from '../../../enums/ActionEnums';
//* Custom hooks
import useClickOutside from '../../../customHooks/useClickOutside';
//* Styles
import './CampaignPopup.scss';

const iframeStyles = {
  width: '100%',
  minHeight: '175px',
  border: 'none',
  overflowX: 'auto',
  overflowY: 'hidden'
} as React.CSSProperties;

const CampaignPopup = () => {
  const popupRef = useRef(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { state, dispatch } = useContext(AppContext);
  const [height, setHeight] = useState('0px');

  useClickOutside(popupRef, () => closePopUp());

  const closePopUp = () => {
    dispatch({
      type: Actions.HideCampaignPopup
    });
  };

  useEffect(() => {
    if (state.campaignPopup.open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [state.campaignPopup.open]);

  const onLoad = () => {
    setHeight(`${iframeRef?.current?.contentWindow?.document.body.scrollHeight}px`);
  };

  const renderPopup = () => {
    if (state.campaignPopup.open) {
      return (
        <Fade in timeout={500}>
          <Box
            className="campaign-popup"
            aria-describedby="alert-dialog-slide-description"
            sx={{
              '.MuiDialog-container': {
                alignItems: 'center'
              }
            }}
          >
            <Box ref={popupRef} className="iframe-container" height={height}>
              <Grow in timeout={1500}>
                <iframe
                  ref={iframeRef}
                  title="Preview"
                  onLoad={onLoad}
                  height={height}
                  allowFullScreen
                  style={iframeStyles}
                  srcDoc={state.campaignPopup.content}
                />
              </Grow>
            </Box>
          </Box>
        </Fade>
      );
    } else {
      return <></>;
    }
  };

  return renderPopup();
};

export default CampaignPopup;
