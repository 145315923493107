import { Box } from '@mui/material';
import React from 'react';
import logoClone from '../../../assets/logo_clone_light.svg';
import logo from '../../../assets/logo_light.svg';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../enums/RouteEnums';
import './Logo.scss';

const Logo: React.FC = () => {
  const isClone = process.env.ENVIRONMENT === 'licence';
  const navigate = useNavigate();
  return (
    <Box className="logo-container">
      <img
        src={isClone ? logoClone : logo}
        alt="logo"
        onClick={() => navigate(PublicRoutes.Home)}
      />
    </Box>
  );
};

export default Logo;
