import { Actions } from '../enums/ActionEnums';
import { ActionType, CampaignPopupType } from '../models/appcontext';

export const campaignPopupReducer = (
  state: CampaignPopupType,
  action: ActionType
): CampaignPopupType => {
  switch (action.type) {
    case Actions.ShowCampaignPopup:
      return {
        open: true,
        content: action.payload.content
      };
    case Actions.HideCampaignPopup:
      return { ...state, open: false, content: '' };
    default:
      return state;
  }
};
