import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes, PublicRoutes } from '../../../enums/RouteEnums';
import { useTranslation } from 'react-i18next';
import './SignupPageNew.scss';
import { isValidEmail } from '../../../utils/validators';
import { Payments } from '@mui/icons-material';
import { labelStyle } from '../SigninPage';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { currencies, CurrencyType } from '../../../utils/currencies';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import { countries, CountryType } from '../../../utils/countries';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Query } from '../../../enums/RequestEnums';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LanguagesQuery } from '../../../queries/languages';
import { AppContext } from '../../../AppContext';
import { handleError, inputPropsStyle, getBorderColor, getIconColor } from '../../../utils/ui';
import { ILanguageBaseModel } from '../../../models/language';
import { RegisterOtpModel, VerifyOtpModel } from '../../../models/account';
import OtpForm from './OtpForm';
import { Actions } from '../../../enums/ActionEnums';
import { GenerateAndSendOtpQuery, ResendOtpQuery, VerifyOtpQuery } from '../../../queries/account';
import { AppConsts } from '../../../enums/AppConsts';
import useSetMyAccountMenuIndex from '../../../customHooks/useSetMyAccountMenuIndex';
import Loader from '../../Common/Loader';
import Logo from '../SigninNew/Logo';
import Header from '../SigninNew/Header';
import CustomSwitch from '../../Common/Switch/CustomSwitch';

const checkBoxLabel = (label: string) => {
  return <Box className="checkbox-label">{label}</Box>;
};

const checkBoxStyle: React.CSSProperties = {
  padding: 0
};

export type IErrors = {
  email: string | null;
  country: string | null;
  phone: string | null;
  username: string | null;
  currency: string | null;
  phoneCode: string | null;
};

const defaultErrorsObj: IErrors = {
  email: null,
  country: null,
  phone: null,
  username: null,
  currency: null,
  phoneCode: null
};

const SignupPageNew: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const setMyAccountIndex = useSetMyAccountMenuIndex();
  const setMyAccountDepositIndex = () => setMyAccountIndex(1);
  const navigate = useNavigate();
  const [cookies] = useCookies(['country', 'language']);
  const cookieCountryValue = cookies?.country;
  const cookieLanguageValue = cookies?.language;
  const languagesGetQuery = useQuery({
    queryKey: Query.Languages,
    queryFn: LanguagesQuery,
    onSuccess: ({ data }) => {
      setLanguages(data);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [languages, setLanguages] = useState<ILanguageBaseModel[] | []>(
    languagesGetQuery.data?.data || []
  );
  const defaultLanguage = languages.find((l) => l.code.toLowerCase() === 'en') || null;

  const userLanguage = useMemo(() => {
    if (cookieLanguageValue) {
      const language = languages.find(
        (l) => l.code.toLowerCase() === cookieLanguageValue?.toLowerCase()
      );
      return language || defaultLanguage;
    }
    return defaultLanguage;
  }, [cookieLanguageValue, languages]);

  const cookieCountry = useMemo(() => {
    return countries.find(
      (c) => c.label.toLowerCase() === cookieCountryValue?.toLowerCase()
    ) as CountryType;
  }, [cookieCountryValue]);

  const { affid, cxd } = state.affiliateData;
  const { t } = useTranslation();
  const [disableResend, setDisableResend] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [currency, setCurrency] = useState<CurrencyType | null>(
    cookieCountry?.label === 'United Kingdom' ? currencies[1] : currencies[0]
  );
  const [country] = useState<CountryType | null>(cookieCountry || null);
  const [phone, setPhone] = useState<string>('');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(cookieCountry || null);
  const [termsAndCond, setTermsAndCond] = useState<boolean>(true);
  const [isOver18YearsOld, setIsOver18YearsOld] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [openOtpForm, setOpenOtpForm] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [sendSms, setSendSms] = useState<boolean>(false);

  const tooltipMessage = sendSms
    ? t('account.emailTooltipMessage')
    : t('account.smsTooltipMessage');

  const generateAndSendOtp = useMutation(GenerateAndSendOtpQuery, {
    onSuccess: () => {
      setOpenOtpForm(true);
      setDisableResend(true);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const verifyOtp = useMutation(VerifyOtpQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      setMyAccountDepositIndex();
      setOpenOtpForm(false);

      dispatch({
        type: Actions.ShowPopup,
        payload: {
          isCongrat: true,
          state: t('account.confirmationRegistration'),
          buttonText: 'OK'
        }
      });
      navigate(AuthRoutes.MyAccount);
    },
    onError: ({ response }) => {
      if (response?.data?.error === 'Verification code has expired. Please request a new one.') {
        setOpenOtpForm(false);
      }
      handleError(response, dispatch);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  const resendOtp = useMutation(ResendOtpQuery, {
    onError: ({ response }) => handleError(response, dispatch)
  });

  const getIpAddressInfo = () => {
    axios
      .get('https://api.ipify.org/?format=json')
      .then((response) => {
        const data = response.data;
        setIpAddress(data.ip);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIpAddressInfo();
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.signup.email') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.signup.username') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handleCurrencyChange = (_: unknown, value: CurrencyType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, currency: t('errors.signup.currency') });
    } else {
      setFormErrors({ ...formErrors, currency: null });
    }
    setCurrency(value);
  };

  const handleTermsAndCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setTermsAndCond(value);
  };

  const handleIsOver18YearsOldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setIsOver18YearsOld(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const isValidState = () => {
    if (isEmailFormValid()) {
      if (sendSms) {
        return isPhoneFormValid();
      }
      return true;
    }
    return false;
  };

  const isEmailFormValid = () => {
    return (
      username.length > 4 &&
      currency !== null &&
      isValidEmail(email) &&
      termsAndCond &&
      isOver18YearsOld
    );
  };

  const isPhoneFormValid = () => {
    return (
      username.length > 4 &&
      currency !== null &&
      phone.length >= 4 &&
      phoneCountry !== null &&
      termsAndCond &&
      isOver18YearsOld
    );
  };

  const getRegisterOtpModel = async () => {
    const model: RegisterOtpModel = {
      email,
      phoneNumber: phone.length < 2 ? '' : `+${phoneCountry?.phone as string}${phone}`,
      country: (country?.label as string)?.replace(/\s/g, ''),
      username,
      currency: currency?.code as string,
      language: userLanguage?.code as string,
      affiliateToken: cxd as string | null,
      affiliateId: affid as string | null,
      affiliate: cxd ? 'Cellxpert' : null,
      ipAddress: ipAddress,
      landingPage: state.affiliateData.landingPage
    };
    return model;
  };

  const handleOtpSubmit = async (value: number) => {
    const model = await getRegisterOtpModel();
    const verifyModel: VerifyOtpModel = {
      ...model,
      otp: value
    };
    verifyOtp.mutate(verifyModel);
  };

  const handleOtpResendSubmit = async () => {
    const model = await getRegisterOtpModel();
    resendOtp.mutate(model);
  };

  const handleSubmit = async () => {
    const model = await getRegisterOtpModel();
    generateAndSendOtp.mutate(model);
  };

  const handleSendSmsChange = () => {
    setSendSms(!sendSms);
  };

  return (
    <Box className="main">
      <Box className="main__content-box">
        <Logo />
        <Box className="main__content-box__inner-content">
          <Box className="main__content-box__inner-content__container">
            <Header
              titleText={t('account.signUp') as string}
              subtitleText={t('account.signUpSubtitle2') as string}
              showTabs={false}
            />
            <Box className="main__content-box__inner-content__container__email-inputs-container">
              <Box>
                <InputLabel htmlFor="username" style={labelStyle}>
                  {t('account.username')}
                </InputLabel>
                <TextField
                  id="username"
                  name="username"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                  value={username}
                  onChange={handleUsernameChange}
                  error={formErrors.username !== null}
                  helperText={formErrors.username ? formErrors.username : ''}
                  sx={{
                    height: '46px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: getBorderColor(username)
                      }
                    }
                  }}
                  inputProps={{ sx: inputPropsStyle }}
                  placeholder={t('account.usernamePlaceHolder') as string}
                  color="info"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle sx={{ fill: getIconColor(username, formErrors.username) }} />
                      </InputAdornment>
                    )
                  }}
                />
                <InputLabel htmlFor="currency" style={labelStyle}>
                  {t('account.currencyLabel')}
                </InputLabel>
                <Autocomplete
                  id="currencyAutocomplate"
                  fullWidth
                  value={currency}
                  onChange={handleCurrencyChange}
                  options={currencies}
                  getOptionLabel={(option) => option.code}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="currencyInput"
                      name="currencyInput"
                      margin="normal"
                      variant="outlined"
                      error={formErrors.currency !== null}
                      helperText={formErrors.currency ? formErrors.currency : ''}
                      sx={{
                        height: '46px',
                        '& .MuiOutlinedInput-root': {
                          height: '46px',
                          padding: '0 !important',
                          '& fieldset': {
                            borderColor: getBorderColor(currency?.code)
                          }
                        }
                      }}
                      placeholder={t('account.currencyPlaceHolder') as string}
                      color="info"
                      inputProps={{
                        ...params.inputProps,
                        sx: inputPropsStyle
                        // autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start" className="currency-input-adornment">
                            <Payments
                              sx={{ fill: getIconColor(currency?.code, formErrors.country) }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Box>
              <EmailForm
                formErrors={formErrors}
                email={email}
                handleEmailChange={handleEmailChange}
              />
              <Tooltip title={tooltipMessage} placement="top">
                <Box className="sms-switch-container">
                  <CustomSwitch
                    checked={sendSms as boolean}
                    handleChecked={() => handleSendSmsChange()}
                    label={t('account.smsSwitchLabel') as string}
                  />
                </Box>
              </Tooltip>
              {sendSms && (
                <PhoneForm
                  formErrors={formErrors}
                  phone={phone}
                  phoneCountry={phoneCountry}
                  handlePhoneCodeChange={handlePhoneCodeChange}
                  handlePhoneChange={handlePhoneChange}
                />
              )}
            </Box>
            <Box className="main__content-box__inner-content__container__check-box-container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAndCond}
                      onChange={handleTermsAndCondChange}
                      size="small"
                      color="info"
                      sx={checkBoxStyle}
                      required
                    />
                  }
                  label={checkBoxLabel(t('account.agreeTermsAndConditions'))}
                  className="form-control-label"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isOver18YearsOld}
                      onChange={handleIsOver18YearsOldChange}
                      size="small"
                      color="info"
                      sx={checkBoxStyle}
                    />
                  }
                  label={checkBoxLabel(t('account.agreeOver18YearsOld'))}
                  className="form-control-label"
                />
              </FormGroup>
            </Box>
            <Box className="main__content-box__inner-content__container__button-container">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isValidState() || generateAndSendOtp.isLoading}
                className="main__content-box__inner-content__container__button-container__custom-button"
                onClick={handleSubmit}
              >
                {t('account.signupButtonText')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="main__already-account">
          {`${t('account.alreadyHaveAnAccount')}? `}
          <Link
            onClick={() => navigate(PublicRoutes.Signin)}
            color="info.main"
            variant="body2"
            className="main__already-account__sign-in-link"
          >
            {t('account.signIn')}
          </Link>
        </Box>
      </Box>
      <Box className="main__background-box" />
      {openOtpForm && (
        <OtpForm
          handleClose={() => setOpenOtpForm(false)}
          onSubmit={handleOtpSubmit}
          onResendSubmit={handleOtpResendSubmit}
          otpByEmail={!sendSms}
          disableResend={disableResend}
        />
      )}
      <Loader loading={generateAndSendOtp.isLoading || verifyOtp.isLoading} />
    </Box>
  );
};

export default SignupPageNew;
