import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
//* Assets
import ShieldBubble from '../../../assets/profile/shield-bubble.svg';
//* MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputAdornment,
  TextField
} from '@mui/material';
//* MUI Icons
import LockOutlined from '@mui/icons-material/LockOutlined';
import { ArrowForwardIosSharp, Done } from '@mui/icons-material';
//* Components
import Loader from '../../Common/Loader';
import WithdrawalSummary from './WithdrawalSummary/WithdrawalSummary';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import ReceivingDetailsCard from './WithdrawReceivingDetails/ReceivingDetailsCard/ReceivingDetailsCard';
import ReceivingDetailsBitcoin from './WithdrawReceivingDetails/ReceivingDetailsBitcoin/ReceivingDetailsBitcoin';
import ReceivingDetailsBankTranfer from './WithdrawReceivingDetails/ReceivingDetailsBankTranfer/ReceivingDetailsBankTranfer';
//* Queries
import { Withdraw } from '../../../queries/wallet';
//* Models
import { IBankDetails, IWithdrawModel, ICardWithdrawOptionModel } from '../../../models/wallet';
import { VerificationStatuses } from '../../../models/account';
//* Enums
import { Actions } from '../../../enums/ActionEnums';
import { AuthRoutes, PublicRoutes } from '../../../enums/RouteEnums';
//* Utils
import { colors } from '../../../utils/theme';
import { getUserCurrencySymbol, handleError } from '../../../utils/ui';
import { IPaymentMethodDto, withdrawPaymentMethods } from '../../../utils/paymentConstants';
//* Styles
import './Withdraw.scss';
import { CountryType } from '../../../utils/countries';
import { cardExpIsInFuture } from '../../../utils/validators';

type IErrors = {
  iban: string | null;
  amount: string | null;
  bitcoinWallet: string | null;
  creditCardNumber: string | null;
};

const defaultErrorsObj: IErrors = {
  iban: null,
  amount: null,
  bitcoinWallet: null,
  creditCardNumber: null
};

const WithdrawPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);
  const [iban, setIban] = useState('');
  const [cardDetails, setCardDetails] = useState<ICardWithdrawOptionModel>({
    cardHolderName: null,
    creditCardNumber: null,
    cardExpiration: null,
    ipAddress: null
  });
  console.log(cardDetails);
  const [expanded, setExpanded] = useState(0);
  const [bitcoinWallet, setBitcoinWallet] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<IPaymentMethodDto | null>(null);
  const [bankDetails, setBankDetails] = useState<IBankDetails>({
    bankName: null,
    branch: null,
    country: null,
    swift: null,
    city: null,
    address: null,
    postCode: null
  });

  const { mutate, isLoading } = useMutation(Withdraw, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.withdrawPage.successfull')
        }
      });
      dispatch({
        type: Actions.UpdateBalance,
        payload: data
      });
      navigate(PublicRoutes.Home);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('withdraw');
    }
  });

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const isIbanValid = () => iban.length > 10;
  const isBitcoinWalletValid = () => bitcoinWallet.length > 10;

  const isAmountValid = (value: number | null) =>
    value !== null &&
    value > 0 &&
    value <= (state.user.data?.realBalance as number) &&
    value >= 200 &&
    value <= 5000;

  const makeWithdraw = () => {
    const model: IWithdrawModel = {
      amount: selectedAmount as number,
      iban: iban as string,
      bitcoinWallet: bitcoinWallet as string
    };

    if (selectedPayment?.name.toLowerCase() === 'bank transfer') {
      if (bankDetails.country) {
        const details = { ...bankDetails, country: bankDetails.country.replace(/\s/g, '') };

        model.bankDetails = details;
      }
    }

    if (selectedPayment?.name.toLowerCase() === 'card') {
      if (cardDetails.creditCardNumber) {
        model.cardDetails = cardDetails;
      }
    }

    mutate(model);
  };

  const isPaymentPermitted = (p: IPaymentMethodDto) =>
    p.permissions === undefined ||
    !p.permissions.length ||
    (p.permissions.length &&
      state.user.data &&
      p.permissions.includes(state.user.data.verificationStatus));

  const handleIbanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      iban: value.length > 10 ? null : t('wallet.withdrawPage.ibanHelperText')
    });
    setIban(value);
    setBitcoinWallet('');
  };

  const handleBitcoinWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setFormErrors({
      ...formErrors,
      bitcoinWallet: value.length > 10 ? null : t('wallet.withdrawPage.bitcoinHelperText')
    });
    setBitcoinWallet(value);
    setIban('');
    setBankDetails({
      bankName: null,
      branch: null,
      country: null,
      swift: null,
      city: null,
      address: null,
      postCode: null
    });
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value ? parseInt(event.currentTarget.value) : null;
    setFormErrors({
      ...formErrors,
      amount: isAmountValid(value)
        ? null
        : t('wallet.withdrawPage.amountHelperText').toString().replace(/\€/g, userCurrency)
    });
    setSelectedAmount(value);
  };

  const handleAmountError = (amount: number | null) => {
    let hasAmountError = false;

    if (amount && state?.user?.data?.realBalance) {
      hasAmountError = amount === null || amount < 200 || amount > state.user.data.realBalance;
    }

    return hasAmountError;
  };

  const handleSetMaxAvailableAmount = () => {
    setSelectedAmount(state.user.data?.realBalance || 0);

    const selectedMaxAmountHasError = handleAmountError(state.user.data?.realBalance || 0);

    if (!selectedMaxAmountHasError) {
      setExpanded(2);
    }
  };

  const handleSetBankDetails = (field: string, value: string | CountryType | null) => {
    setBankDetails({ ...bankDetails, [field]: value });
  };

  const handleReceivingDetailsError = () => {
    let details: IBankDetails | ICardWithdrawOptionModel = bankDetails;

    if (selectedPayment?.name.toLowerCase() === 'card') {
      details = cardDetails;
    }

    const detailsValues = Object.values(details);
    const detailsHaveError = detailsValues.every((value) => value && value.length > 0);

    return !detailsHaveError;
  };

  const renderWithdrawReceivingDetails = () => {
    let withdrawReceivingDetails = null;

    switch (selectedPayment?.name.toLowerCase()) {
      case 'bitcoin':
        withdrawReceivingDetails = (
          <ReceivingDetailsBitcoin
            bitcoinWallet={bitcoinWallet}
            error={formErrors.bitcoinWallet}
            handleBitcoinWalletChange={handleBitcoinWalletChange}
          />
        );
        break;
      case 'bank transfer':
        withdrawReceivingDetails = (
          <ReceivingDetailsBankTranfer
            iban={iban}
            bankDetails={bankDetails}
            ibanError={formErrors.iban}
            handleIbanChange={handleIbanChange}
            handleSetBankDetails={handleSetBankDetails}
          />
        );
        break;
      case 'card':
        withdrawReceivingDetails = (
          <ReceivingDetailsCard
            cardDetails={cardDetails}
            handleSetCardDetails={handleSetCardDetails}
            creditCardNumberError={formErrors.creditCardNumber}
          />
        );
        break;
      default:
        withdrawReceivingDetails = <>Invalid withdraw option</>;
        break;
    }

    return withdrawReceivingDetails;
  };

  const validateLuhnAlgorithm = (cardNumber: string | undefined) => {
    let sum = 0;
    let isEven = false;
    let isValid = false;

    if (cardNumber) {
      for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i), 10);

        if (isEven) {
          digit *= 2;

          if (digit > 9) {
            digit -= 9;
          }
        }

        sum += digit;
        isEven = !isEven;
      }

      isValid = sum % 10 === 0;
    }

    return isValid;
  };

  const luhnAlgorithmIsValid = validateLuhnAlgorithm(cardDetails.creditCardNumber?.toString());

  const handleIsPaymentMethodValid = () => {
    if (selectedPayment) {
      if (selectedPayment.name === 'Bitcoin' && isBitcoinWalletValid()) {
        return true;
      }

      if (selectedPayment.name === 'Card') {
        const cardDetailsValues = Object.values(cardDetails);
        const cardDetailsAreValid = cardDetailsValues.every((value) => value && value.length > 0);

        return luhnAlgorithmIsValid && cardDetailsAreValid;
      } else {
        if (isIbanValid()) {
          return true;
        }
      }
    }

    return false;
  };

  const isPaymentMethodValid = handleIsPaymentMethodValid();
  const receivingetailsHaveError = handleReceivingDetailsError();

  const handleSetCardDetails = (cardDetails: ICardWithdrawOptionModel, cardDetailsKey = '') => {
    setCardDetails(cardDetails);

    if (cardDetailsKey === 'creditCardNumber') {
      setFormErrors({
        ...formErrors,
        creditCardNumber:
          !luhnAlgorithmIsValid && cardDetails.creditCardNumber?.length === 16
            ? null
            : t('wallet.withdrawPage.creditCardError')
      });
    }
  };

  const handleButtonDisable = () => {
    let buttonIsDisabled =
      selectedPayment === null ||
      selectedAmount === null ||
      !isPaymentMethodValid ||
      selectedAmount < 200;

    if (selectedPayment?.name.toLowerCase() === 'bank transfer') {
      buttonIsDisabled =
        selectedPayment === null ||
        selectedAmount === null ||
        !isPaymentMethodValid ||
        receivingetailsHaveError ||
        selectedAmount < 200;
    }

    if (selectedPayment?.name.toLowerCase() === 'card') {
      buttonIsDisabled =
        selectedPayment === null ||
        selectedAmount === null ||
        !cardExpIsInFuture(cardDetails.cardExpiration) ||
        !luhnAlgorithmIsValid ||
        (receivingetailsHaveError &&
          cardDetails.creditCardNumber &&
          cardDetails.creditCardNumber?.length < 16) ||
        selectedAmount < 200;
    }

    return buttonIsDisabled;
  };

  const isUserVerified = state.user.data?.verificationStatus === VerificationStatuses.Verified;
  const selectedAmountHasError = handleAmountError(selectedAmount);
  const isWithdrawalMethodSelected =
    (selectedPayment && selectedPayment?.name.length > 0) || undefined;
  const buttonIsDisabled = handleButtonDisable();

  const renderPaymentMethodImage = (paymentMethod: IPaymentMethodDto) => {
    const paymentMethodCustomClass = paymentMethod.name.replace(/\s/g, '-');

    const paymentMethodImage = (
      <Box
        alt={'logo'}
        component="img"
        src={paymentMethod.img as string}
        className={`withdraw__accordion-content__cards__element-image withdraw__accordion-content__cards__element-image--${paymentMethodCustomClass.toLowerCase()}`}
      />
    );

    return paymentMethodImage;
  };

  const renderWithdrawPaymentMethods = () => {
    const paymentMethods = withdrawPaymentMethods.map((paymentMethod, index) => {
      const isDisabled =
        !isUserVerified &&
        paymentMethod.permissions &&
        paymentMethod.permissions.some((method) => method.toLowerCase() === 'verified');

      const paymentMethodImage = renderPaymentMethodImage(paymentMethod);

      return (
        <Box
          key={index}
          className={`withdraw__accordion-content__cards__element ${
            selectedPayment?.name === paymentMethod.name
              ? 'withdraw__accordion-content__cards__element--is-selected'
              : ''
          } ${isDisabled ? 'withdraw__accordion-content__cards__element--is-disabled' : ''}`}
          onClick={() => {
            if (!isDisabled && isPaymentPermitted(paymentMethod)) {
              setSelectedPayment(paymentMethod);
              setExpanded(1);
            }
          }}
        >
          {isDisabled && (
            <Box className="withdraw__accordion-content__cards__element-lock">
              <LockOutlined />
            </Box>
          )}
          {paymentMethodImage}
        </Box>
      );
    });

    return paymentMethods;
  };

  return (
    <>
      {!isUserVerified && (
        <Box className="verification">
          <Box component="img" src={ShieldBubble} />
          <Box className="verification__message">
            {t('wallet.withdrawPage.accountStatusWarning')}
          </Box>
          <PrimaryButton
            className="verification__button"
            onClick={() => navigate(AuthRoutes.MyAccount)}
            text={t('wallet.withdrawPage.verifyAccountButton') as string}
          />
        </Box>
      )}
      <Box className="withdraw">
        <Accordion
          expanded={expanded === 0}
          onChange={handleChange(0)}
          className="withdraw__accordion"
        >
          <AccordionSummary
            id={`withdraw-panel-payment-method-header`}
            aria-controls={`withdraw-panel-payment-method-content`}
            expandIcon={<ArrowForwardIosSharp className="withdraw__accordion-expand-icon" />}
          >
            <Box
              className={`withdraw__accordion-title ${
                selectedPayment ? 'withdraw__accordion-title--done' : ''
              }`}
            >
              {selectedPayment && (
                <Box className="withdraw__accordion-title-icon">
                  <Done />
                </Box>
              )}
              {t('wallet.withdrawPage.withdrawMethod')}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="withdraw__accordion-content withdraw__accordion-content__cards">
              {renderWithdrawPaymentMethods()}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 1}
          onChange={handleChange(1)}
          className="withdraw__accordion"
        >
          <AccordionSummary
            id={`withdraw-panel-payment-amount-header`}
            aria-controls={`withdraw-panel-payment-amount-content`}
            expandIcon={<ArrowForwardIosSharp className="withdraw__accordion-expand-icon" />}
          >
            <Box
              className={`withdraw__accordion-title ${
                selectedAmount && !selectedAmountHasError ? 'withdraw__accordion-title--done' : ''
              }`}
            >
              {selectedAmount !== null && !selectedAmountHasError && (
                <Box className="withdraw__accordion-title-icon">
                  <Done />
                </Box>
              )}
              {t('wallet.withdrawPage.setAmount')}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="withdraw__accordion__input">
              <TextField
                fullWidth
                color="info"
                type="number"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={selectedAmount || ''}
                error={selectedAmountHasError}
                placeholder={t('wallet.depositPage.amountPlaceholder') as string}
                onChange={(e) => handleAmountChange(e as React.ChangeEvent<HTMLInputElement>)}
                helperText={
                  <Box className="withdraw__accordion__input-helper">
                    <Box
                      className={`withdraw__accordion__input-helper-text ${
                        selectedAmountHasError
                          ? 'withdraw__accordion__input-helper-text--error'
                          : ''
                      }`}
                    >
                      {t('wallet.withdrawPage.minWithdrawal')} {userCurrency}200 |{' '}
                      {t('wallet.withdrawPage.maxAmountAvailable')} {userCurrency}
                      {state.user.data?.realBalance}
                    </Box>
                    <button
                      onClick={handleSetMaxAvailableAmount}
                      className="withdraw__accordion__input-helper-button"
                    >
                      {t('common.button.max')}
                    </button>
                  </Box>
                }
                FormHelperTextProps={{
                  sx: { fontSize: '14px', lineHeight: '20px', color: colors.grey.placeholder }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className="withdraw__accordion__input-adornment"
                    >
                      {userCurrency}
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 2}
          onChange={handleChange(2)}
          className={`withdraw__accordion ${
            !isWithdrawalMethodSelected ? 'withdraw__accordion--is-disabled' : ''
          }`}
          disabled={!isWithdrawalMethodSelected}
        >
          <AccordionSummary
            id={`withdraw-panel-payment-amount-header`}
            aria-controls={`withdraw-panel-payment-amount-content`}
            expandIcon={<ArrowForwardIosSharp className="withdraw__accordion-expand-icon" />}
          >
            <Box
              className={`withdraw__accordion-title ${
                isPaymentMethodValid && !receivingetailsHaveError
                  ? 'withdraw__accordion-title--done'
                  : ''
              }`}
            >
              {isPaymentMethodValid && !receivingetailsHaveError && (
                <Box className="withdraw__accordion-title-icon">
                  <Done />
                </Box>
              )}
              {t('wallet.withdrawPage.receivingDetails')}
            </Box>
          </AccordionSummary>
          <AccordionDetails>{renderWithdrawReceivingDetails()}</AccordionDetails>
        </Accordion>
        <WithdrawalSummary amount={selectedAmount} userCurrency={userCurrency} />
        <Button
          variant="contained"
          disabled={buttonIsDisabled || selectedAmountHasError}
          className="withdraw__button"
          onClick={() => makeWithdraw()}
          sx={{
            background: colors.gradients.yellow,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              background: (t) => t.palette.common.white,
              boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
            },
            color: 'primary.main'
          }}
        >
          {t('wallet.withdraw')}
        </Button>
        <Loader loading={isLoading} />
      </Box>
    </>
  );
};

export default WithdrawPage;
