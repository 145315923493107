import React, { useState, useRef, useEffect } from 'react';
import { Box, Dialog, Link } from '@mui/material';
import { Transition } from '../../Common/Dialogs/DialogBox';
import { useTranslation } from 'react-i18next';
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
import { Close } from '@mui/icons-material';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import CustomInputField from '../../Profile/SecurityAndSettings/CustomInputField';
import './OtpForm.scss';

interface OtpFormProps {
  handleClose: () => void;
  isLogin?: boolean;
  onSubmit: (opt: number) => void;
  onResendSubmit: () => void;
  disableResend?: boolean;
  otpByEmail: boolean;
}

const OtpForm: React.FC<OtpFormProps> = ({
  handleClose,
  onSubmit,
  onResendSubmit,
  disableResend,
  otpByEmail = false,
  isLogin = false
}) => {
  const { t } = useTranslation();
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const [firstDigit, setFirstDigit] = useState<number | ''>('');
  const [secondDigit, setSecondDigit] = useState<number | ''>('');
  const [thirdDigit, setThirdDigit] = useState<number | ''>('');
  const [fourthDigit, setFourthDigit] = useState<number | ''>('');
  const firstInputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);
  const thirdInputRef = useRef<HTMLInputElement>(null);
  const fourthInputRef = useRef<HTMLInputElement>(null);

  const isOtpValid =
    firstDigit !== '' && secondDigit !== '' && thirdDigit !== '' && fourthDigit !== '';

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text').replace(/\D/g, ''); // Remove non-digits
    const digits = pastedData.split('').slice(0, 4); // Take only the first 4 digits

    if (digits.length > 0) setFirstDigit(Number(digits[0]));
    if (digits.length > 1) setSecondDigit(Number(digits[1]));
    if (digits.length > 2) setThirdDigit(Number(digits[2]));
    if (digits.length > 3) setFourthDigit(Number(digits[3]));

    // Focus the next empty input
    const refs = [firstInputRef, secondInputRef, thirdInputRef, fourthInputRef];
    const nextRef = refs[digits.length] || refs[3];
    nextRef.current?.focus();

    // Prevent default paste behavior
    event.preventDefault();
  };

  useEffect(() => {
    if (isOtpValid) {
      handleOtpSubmit();
    }
  }, [firstDigit, secondDigit, thirdDigit, fourthDigit]);

  useEffect(() => {
    if (disableResend) {
      setIsResendDisabled(true);
      setTimer(30);
    }
  }, [disableResend]);

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (isResendDisabled && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [isResendDisabled, timer]);

  const formatTime = (time: number) => {
    return `${time}`;
  };
  const handleOtpSubmit = () => {
    const opt = Number(`${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}`);
    onSubmit(opt);
  };

  const handleResendSubmit = () => {
    if (isResendDisabled) return;
    onResendSubmit();
    setIsResendDisabled(true);
    setTimer(30);
  };

  const handleFirstDigitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFirstDigit(value ? Number(value) : '');
    if (value && secondInputRef.current) {
      secondInputRef.current.focus();
    }
  };

  const handleSecondDigitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSecondDigit(value ? Number(value) : '');
    if (value && thirdInputRef.current) {
      thirdInputRef.current.focus();
    }
  };

  const handleThirdDigitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setThirdDigit(value ? Number(value) : '');
    if (value && fourthInputRef.current) {
      fourthInputRef.current.focus();
    }
  };

  const handleFourthDigitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFourthDigit(value ? Number(value) : '');
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: 'mui-dialog-otp' }}
    >
      <Box className="otp-dialog__title-icon-container">
        <Box className="otp-dialog__title-icon-container__title-box">
          {`${otpByEmail ? t('account.signUpOptions.email') : t('account.signUpOptions.phone')} ${t(
            'myAccount.verificationPage.title'
          )}`}
        </Box>
        <Box className="otp-dialog__title-icon-container__close-icon-box">
          <CustomIconButton
            size="small"
            icon={<Close color="primary" />}
            onClick={() => handleClose()}
          />
        </Box>
      </Box>
      <Box className="otp-dialog__subtitle-box">
        {`${
          otpByEmail ? t('account.otp.emailEnterMessage') : t('account.otp.phoneNumberEnterMessage')
        }.`}
      </Box>
      <Box className="otp-dialog__form-row-container">
        <CustomInputField
          id="firstDigit"
          value={firstDigit}
          isOneDigit={true}
          onChange={handleFirstDigitChange}
          ref={firstInputRef}
          onPaste={handlePaste}
        />
        <CustomInputField
          id="secondDigit"
          isOneDigit={true}
          value={secondDigit}
          onChange={handleSecondDigitChange}
          ref={secondInputRef}
          onPaste={handlePaste}
        />
        <CustomInputField
          id="thirdDigit"
          isOneDigit={true}
          value={thirdDigit}
          onChange={handleThirdDigitChange}
          ref={thirdInputRef}
          onPaste={handlePaste}
        />
        <CustomInputField
          id="fourthDigit"
          isOneDigit={true}
          value={fourthDigit}
          onChange={handleFourthDigitChange}
          ref={fourthInputRef}
          onPaste={handlePaste}
        />
      </Box>
      <Box>
        <PrimaryButton
          text={isLogin ? 'Login' : t('wallet.withdrawPage.verifyAccountButton')}
          onClick={handleOtpSubmit}
          disabled={!isOtpValid}
          additionalStyle={{
            '&.Mui-disabled': { background: 'rgba(255, 255, 255, 0.3)' },
            width: '310px',
            height: '22px'
          }}
        />
      </Box>
      <Box className="otp-dialog__form-resend-button-container">
        <Box className="otp-dialog__subtitle-box">{`${t('account.otp.codeNotReceived')}?`}</Box>
        <Box>
          <Link
            onClick={handleResendSubmit}
            color="info.main"
            variant="body2"
            className={`main__already-account__sign-in-link ${
              isResendDisabled ? 'main__already-account__sign-in-link--disabled' : ''
            }`}
          >
            {isResendDisabled
              ? `${t('account.otp.resend')} (${formatTime(timer)})`
              : t('account.otp.resend')}
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};

export default OtpForm;
