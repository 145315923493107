import { ChallengeBadge } from './challenges';

export interface LoginModel {
  username: string;
  password: string;
}

export interface UserModel {
  id: string;
  accessToken: string;
  expirationDate: string;
  firstName: string;
  lastName: string;
  verificationStatus: VerificationStatuses;
  agreedEmailPromotions: boolean;
  email: string;
  roles: string[];
  currency: string;
  realBalance: number;
  bonusBalance: number;
  loyaltyPoints: number;
  challengeLevel: ChallengeBadge | null;
  favGames: number[];
  tags: number[];
  wheelSpins: number;
  hasDeposited: boolean;
  isPasswordSetByUser?: boolean;
}

export interface EmailConfirmModel {
  email: string;
  token: string;
}

export interface ResetPasswordModel extends EmailConfirmModel {
  password: string;
  confirmPassword: string;
}

export interface RegisterModel {
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  country: string;
  phoneNumber: string;
  address: string;
  city: string;
  postcode: string;
  username: string;
  password: string;
  confirmPassword: string;
  currency: string;
  language?: string;
  agreedEmailPromotions: boolean;
  agreedSmsPromotions: boolean;
  affiliateId: string | null;
  affiliateToken: string | null;
  affiliate: string | null;
  ipAddress: string | null;
  landingPage: string | null;
}

export interface RegisterOtpModel {
  email: string;
  phoneNumber: string;
  country: string;
  username: string;
  currency: string;
  language?: string;
  affiliateId: string | null;
  affiliateToken: string | null;
  affiliate: string | null;
  ipAddress: string | null;
  landingPage: string | null;
}

export interface RegisterByPasswordModel {
  password: string;
  confirmPassword: string;
  country: string;
  username: string;
  currency: string;
  language?: string;
  affiliateId: string | null;
  affiliateToken: string | null;
  affiliate: string | null;
  ipAddress: string | null;
  landingPage: string | null;
}

export interface VerifyOtpModel extends RegisterOtpModel {
  otp: number;
}

export interface LoginOtpModel {
  email: string;
  phoneNumber: string;
}

export interface VerifyLoginOtpModel extends LoginOtpModel {
  otp: number;
}

export interface EmailModel {
  from: string;
  to: string;
  subject: string;
  content: string;
  attachments: Attachment[];
}

export interface Attachment {
  content: string;
  type: string;
  filename: string;
  disposition?: string;
}

export enum VerificationStatuses {
  NotVerified = 'NotVerified',
  Pending = 'Pending',
  Verified = 'Verified',
  Declined = 'Declined'
}

export enum AccountDetailsStatuses {
  Unverified = 'Unverified',
  Verified = 'Verified',
  Pending = 'Pending',
  Declined = 'Declined'
}

export type AffiliateData = {
  affid: string | null;
  cxd: string | null;
  landingPage: string | null;
};
