import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
//* MUI
import { Box, InputAdornment, TextField } from '@mui/material';
//* MUI icons
import { CreditCard } from '@mui/icons-material';
//* Components
import AutocompleteField from '../../../../Common/Fields/AutocompleteField';
// import ReceivingDetailsWarningMessage from '../ReceivingDetailsWarningMessage/ReceivingDetailsWarningMessage';
//* Models
import { ICardWithdrawOptionModel } from '../../../../../models/wallet';
//* Enums
//* Utils
import { getCardDetails } from '../../../../../utils/profile';
import { countries, CountryType } from '../../../../../utils/countries';
//* Styles
import './ReceivingDetailsCard.scss';

interface IProps {
  creditCardNumberError: string | null;
  cardDetails: ICardWithdrawOptionModel;
  handleSetCardDetails: (cardDetails: ICardWithdrawOptionModel, cardDetailsKey?: string) => void;
}

const ReceivingDetailsCard: React.FunctionComponent<IProps> = ({
  cardDetails,
  handleSetCardDetails,
  creditCardNumberError
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const setIp = async () => {
      const res = await axios.get('https://api.ipify.org/?format=json');
      handleSetCardDetails({ ...cardDetails, ipAddress: res.data.ip });
    };
    setIp();
  }, []);

  const renderCardInputAdornment = () => {
    let cardImage = <CreditCard />;

    if (cardDetails.creditCardNumber && cardDetails?.creditCardNumber?.length >= 2) {
      const cardImageDetails = getCardDetails(cardDetails.creditCardNumber);

      if (cardImageDetails.logo.length > 0) {
        cardImage = (
          <Box className="receiving-details__card-info__input-adornment-img">
            <Box component="img" className={cardImageDetails.type} src={cardImageDetails.logo} />
          </Box>
        );
      }
    }

    return cardImage;
  };

  const handleExpirationChange = (value: string) => {
    const formattedValue = value
      .replace(/\D/g, '') // Remove non-numeric characters
      .slice(0, 6) // Limit to MMYY format
      .replace(/(\d{2})(\d{0,2})/, '$1/$2'); // Add slash between MM and YY
    handleSetCardDetails({ ...cardDetails, cardExpiration: formattedValue });
  };

  return (
    <Box className="receiving-details__bank">
      <Box className="receiving-details__bank-info">
        <TextField
          fullWidth
          color="info"
          margin="none"
          id="otherAmount"
          name="otherAmount"
          value={cardDetails.cardHolderName || ''}
          className="receiving-details__bank-info__input"
          placeholder={t('wallet.withdrawPage.cardHolderName') as string}
          onChange={(event) =>
            handleSetCardDetails({ ...cardDetails, cardHolderName: event.target.value })
          }
        />
        <TextField
          fullWidth
          color="info"
          margin="none"
          type="number"
          id="otherAmount"
          name="otherAmount"
          error={!!creditCardNumberError && creditCardNumberError?.length > 0}
          value={cardDetails.creditCardNumber}
          placeholder={t('wallet.withdrawPage.creditCardNumber') as string}
          className="receiving-details__bank-info__input receiving-details__card-info__input"
          onChange={(event) =>
            handleSetCardDetails(
              { ...cardDetails, creditCardNumber: event.target.value },
              'creditCardNumber'
            )
          }
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className="receiving-details__card-info__input-adornment"
              >
                {renderCardInputAdornment()}
              </InputAdornment>
            )
          }}
          helperText={
            !!creditCardNumberError && creditCardNumberError.length > 0 ? (
              <Box className="withdraw__accordion__input-helper">
                <Box
                  className={`withdraw__accordion__input-helper-text ${
                    creditCardNumberError ? 'withdraw__accordion__input-helper-text--error' : ''
                  }`}
                >
                  {creditCardNumberError}
                </Box>
              </Box>
            ) : null
          }
        />
        <TextField
          color="info"
          margin="none"
          id="card-expiration"
          name="expiration"
          value={cardDetails.cardExpiration || ''}
          className="receiving-details__bank-info__input"
          placeholder={t('wallet.withdrawPage.cardExpiration') as string}
          onChange={(event) => handleExpirationChange(event.target.value)}
        />
      </Box>
    </Box>
  );
};

export default ReceivingDetailsCard;
