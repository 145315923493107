import { AccountCircle, Close, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField
} from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { PublicRoutes } from '../../enums/RouteEnums';
import {
  GenerateAndSendOtpByLoginQuery,
  LoginQuery,
  ResendOtpByLoginQuery,
  VerifyOtpLoginQuery
} from '../../queries/account';
import ResetPasswordPage from '../Account/ForgerPasswordPage';
import { Transition } from './Dialogs/DialogBox';
import Loader from './Loader';
import { getBorderColor, getIconColor, handleError, inputPropsStyle } from '../../utils/ui';
import { labelStyle } from '../Account/SigninPage';
import EmailForm from '../Account/SignupNew/EmailForm';
import PhoneForm from '../Account/SignupNew/PhoneForm';
import { useCookies } from 'react-cookie';
import { countries, CountryType } from '../../utils/countries';
import { isValidEmail } from '../../utils/validators';
import { LoginModel, LoginOtpModel, VerifyLoginOtpModel } from '../../models/account';
import { GroupButtonOption } from '../../models/buttons';
import './LoginpopupNew.scss';
import OtpForm from '../Account/SignupNew/OtpForm';

export type ILoginErrors = {
  email: string | null;
  phone: string | null;
  username: string | null;
  password: string | null;
  phoneCode: string | null;
};

const defaultErrorsObj: ILoginErrors = {
  email: null,
  phone: null,
  username: null,
  password: null,
  phoneCode: null
};

const LoginPopupNew: React.FunctionComponent = () => {
  const [cookies] = useCookies(['country']);
  const { state } = useContext(AppContext);
  const country = cookies?.country;
  const userCountry = useMemo(() => {
    return countries.find((c) => c.label.toLowerCase() === country?.toLowerCase()) as CountryType;
  }, [country]);

  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [visiblePass, setVisiblePass] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState<string>('');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(userCountry || null);
  const [password, setPassword] = useState<string>('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [openOtpForm, setOpenOtpForm] = useState(false);
  const generateAndSendOtp = useMutation(GenerateAndSendOtpByLoginQuery, {
    onSuccess: () => {
      setOpenOtpForm(true);
      setDisableResend(true);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const options: GroupButtonOption[] = [
    { label: t('account.signUpOptions.email') },
    { label: t('account.signUpOptions.phone') },
    { label: t('account.signUpOptions.username') }
  ];

  const resetFormFields = () => {
    setEmail('');
    setUsername('');
    setPhone('');
    setPassword('');
    setFormErrors(defaultErrorsObj);
  };

  const verifyOtp = useMutation(VerifyOtpLoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      setOpenOtpForm(false);
      dispatch({ type: Actions.HideLoginPopup });
      if (state.loginPopup.direction) {
        navigate(state.loginPopup.direction, { state: state.loginPopup.state });
      }
      resetFormFields();
    },
    onError: ({ response }) => {
      if (response?.data?.error === 'Verification code has expired. Please request a new one.') {
        setOpenOtpForm(false);
      }
      handleError(response, dispatch);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  const loginByUserAndPass = useMutation(LoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      dispatch({ type: Actions.HideLoginPopup });
      if (state.loginPopup.direction) {
        navigate(state.loginPopup.direction, { state: state.loginPopup.state });
      }
      resetFormFields();
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  const resendOtp = useMutation(ResendOtpByLoginQuery, {
    onError: ({ response }) => handleError(response, dispatch)
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.signup.email') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.shortUsername') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, password: t('errors.shortPass') });
    } else {
      setFormErrors({ ...formErrors, password: null });
    }
    setPassword(value);
  };

  const isValidState = () => {
    if (page === 0) {
      return isEmailFormValid();
    } else if (page === 1) {
      return isPhoneFormValid();
    } else if (page === 2) {
      return isPasswordFormValid();
    }
  };

  const isEmailFormValid = () => {
    return isValidEmail(email);
  };

  const isPhoneFormValid = () => {
    return phone.length >= 4 && phoneCountry !== null;
  };

  const isPasswordFormValid = () => {
    return username.length > 3 && password.length > 3;
  };

  const getRegisterOtpModel = async () => {
    const model = {
      email,
      phoneNumber: phone.length < 2 ? '' : `+${phoneCountry?.phone as string}${phone}`,
      username
    };
    return model;
  };

  const handleLoginOptionChange = (newPage: number) => {
    resetFormFields();
    setPage(newPage);
  };

  const handleOtpSubmit = async (value: number) => {
    const model = await getRegisterOtpModel();
    const verifyModel: VerifyLoginOtpModel = {
      ...model,
      otp: value
    };
    verifyOtp.mutate(verifyModel);
  };

  const handleOtpResendSubmit = async () => {
    const model = await getRegisterOtpModel();
    resendOtp.mutate(model);
  };

  const handleSubmit = async () => {
    if (page !== 2) {
      const model: LoginOtpModel = {
        email,
        phoneNumber: phone.length < 2 ? '' : `+${phoneCountry?.phone as string}${phone}`
      };
      generateAndSendOtp.mutate(model);
    } else {
      const model: LoginModel = {
        password: password,
        username: username
      };
      loginByUserAndPass.mutate(model);
    }
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const getInfoText = (page: number) => {
    if (page === 0) {
      return t('account.loginEmailInfoText');
    } else if (page === 1) {
      return t('account.loginPhoneInfoText');
    } else if (page === 2) {
      return t('account.loginUserPassInfoText');
    }
  };

  return (
    <Dialog
      open={state.loginPopup.open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: 'mui-dialog-login' }}
      onKeyDown={keyPress}
    >
      <Box sx={{ position: 'relative' }}>
        <Box className="signin-title">{t('account.signIn')}</Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch({ type: Actions.HideLoginPopup });
            resetFormFields();
          }}
          className="close-icon-button"
        >
          <Close />
        </IconButton>
      </Box>
      <Box>
        <ButtonGroup className="button-group" variant="filledTonal" fullWidth>
          {options.map((option, index) => (
            <Button
              key={index}
              onClick={() => handleLoginOptionChange(index)}
              className={`button-group__option ${
                page === index ? 'button-group__option--active' : ''
              }`}
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Box className="info-text">{getInfoText(page)}</Box>
      <Box>
        {page === 2 && (
          <Box>
            <InputLabel htmlFor="username" style={labelStyle}>
              {t('account.username')}
            </InputLabel>
            <TextField
              id="username"
              name="username"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={username}
              onChange={handleUsernameChange}
              error={formErrors.username !== null}
              helperText={formErrors.username ? formErrors.username : ''}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: getBorderColor(username)
                  }
                }
              }}
              inputProps={{ sx: inputPropsStyle }}
              placeholder={t('account.usernamePlaceHolder') as string}
              color="info"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle sx={{ fill: getIconColor(username, formErrors.username) }} />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        )}
        {page === 0 && (
          <EmailForm formErrors={formErrors} email={email} handleEmailChange={handleEmailChange} />
        )}
        {page === 1 && (
          <PhoneForm
            formErrors={formErrors}
            phone={phone}
            phoneCountry={phoneCountry}
            handlePhoneCodeChange={handlePhoneCodeChange}
            handlePhoneChange={handlePhoneChange}
          />
        )}
        {page === 2 && (
          <Box>
            <InputLabel htmlFor="password" style={labelStyle}>
              {t('account.password')}
            </InputLabel>
            {/* <Tooltip title={t('account.passwordRequirements') as string} placement="top"> */}
            <TextField
              id="password"
              name="password"
              margin="normal"
              variant="outlined"
              type={visiblePass ? 'text' : 'password'}
              fullWidth
              required
              value={password}
              onChange={handlePasswordChange}
              error={formErrors.password !== null}
              helperText={formErrors.password ? formErrors.password : ''}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: getBorderColor(password)
                  }
                }
              }}
              inputProps={{ sx: inputPropsStyle }}
              placeholder={t('account.passPlaceholder') as string}
              color="info"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock sx={{ fill: getIconColor(password, formErrors.password) }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisiblePass(!visiblePass)}
                    >
                      {visiblePass ? (
                        <VisibilityOff sx={{ fill: getIconColor(password, formErrors.password) }} />
                      ) : (
                        <Visibility sx={{ fill: getIconColor(password, formErrors.password) }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {/* </Tooltip> */}
          </Box>
        )}
      </Box>
      {page === 2 && (
        <Box className="forgot-password-box">
          <Box>
            <Link
              onClick={() => setOpenResetPass(true)}
              variant="body2"
              color="info.main"
              className="forgot-password-link-button"
            >
              {t('account.forgotPass')}
            </Link>
          </Box>
        </Box>
      )}
      <Box>
        <Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isValidState() || generateAndSendOtp.isLoading}
            className="signin-button-container__custom-signin-button"
            onClick={handleSubmit}
          >
            {t('account.signIn')}
          </Button>
        </Box>
        <Box>
          <Button
            className="create-account-button-container__custom-signin-button"
            fullWidth
            variant="outlined"
            disabled={
              generateAndSendOtp.isLoading || verifyOtp.isLoading || loginByUserAndPass.isLoading
            }
            onClick={() => {
              dispatch({ type: Actions.HideLoginPopup });
              navigate(PublicRoutes.Signup);
              resetFormFields();
            }}
          >
            {t('account.createAccount')}
          </Button>
        </Box>
      </Box>
      {openOtpForm && (
        <OtpForm
          handleClose={() => setOpenOtpForm(false)}
          onSubmit={handleOtpSubmit}
          onResendSubmit={handleOtpResendSubmit}
          otpByEmail={page === 0}
          disableResend={disableResend}
          isLogin={true}
        />
      )}
      {openResetPass && <ResetPasswordPage handleClose={() => setOpenResetPass(false)} />}
      <Loader
        loading={
          generateAndSendOtp.isLoading || verifyOtp.isLoading || loginByUserAndPass.isLoading
        }
      />
    </Dialog>
  );
};

export default LoginPopupNew;
