import { Box, Button, ButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupButtonOption } from '../../../models/buttons';
import './Header.scss';

type HeaderProps = {
  handleOptionChange?: (index: number) => void;
  page?: number;
  titleText?: string;
  subtitleText?: string;
  showTabs?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  handleOptionChange,
  page,
  titleText,
  subtitleText,
  showTabs = true
}) => {
  const { t } = useTranslation();
  const options: GroupButtonOption[] = [
    { label: t('account.signUpOptions.email') },
    { label: t('account.signUpOptions.phone') },
    { label: t('account.signUpOptions.username') }
  ];
  return (
    <Box>
      <Box className="title-container">{titleText}</Box>
      <Box className="subtitle-container">{subtitleText}</Box>
      {showTabs && (
        <Box className="group-container">
          <ButtonGroup className="group-container__button-group" variant="filledTonal" fullWidth>
            {options.map((option, index) => (
              <Button
                key={index}
                onClick={() => handleOptionChange && handleOptionChange(index)}
                className={`group-container__button-group__option ${
                  page === index ? 'group-container__button-group__option--active' : ''
                }`}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      )}
    </Box>
  );
};

export default Header;
