export enum Actions {
  SetUser = 'set_user',
  RemoveUser = 'remove_user',
  ShowMessage = 'show_message',
  HideMessage = 'hide_message',
  UpdateBalance = 'update_balance',
  ShowLoginPopup = 'show_login_popup',
  HideLoginPopup = 'hide_login_popup',
  ShowPopup = 'show_popup',
  HidePopup = 'hide_popup',
  SetGames = 'set_games',
  ShowWheelPopup = 'show_wheel_popup',
  HideWheelPopup = 'hide_wheel_popup',
  UpdateUserLoyaltyPoints = 'update_user_loyalty_points',
  UpdateBonusBalance = 'update_bonus_balance',
  UpdateUserBadge = 'update_user_badge',
  UpdateUserWheelSpins = 'update_user_wheel_spins',
  AddUserWheelSpins = 'add_user_wheel_spins',
  DepositAmount = 'deposit_amount',
  BalanceLoading = 'balance_loading',
  UpdateAffiliateData = 'update_affiliate_data',
  SetMyAccountMenuIndex = 'set_my_account_menu_index',
  ShowConfirmation = 'show_confirmation',
  HideConfirmation = 'hide_confirmation',
  SetLandingPage = 'set_landing_page',
  ShowCampaignPopup = 'show_campaign_popup',
  HideCampaignPopup = 'hide_campaign_popup'
}
