import React, { useContext, useState } from 'react';
import { Box, Dialog, IconButton, InputAdornment } from '@mui/material';
import { Transition } from '../../Common/Dialogs/DialogBox';
import './UpdatePasswordForm.scss';
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomInputField from './CustomInputField';
import PasswordRequirements from './PasswordRequirements';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../../AppContext';
import { ChangePasswordQuery } from '../../../queries/profile';
import { handleError } from '../../../utils/ui';
import { ChangePasswordModel } from '../../../models/profile';
import { Actions } from '../../../enums/ActionEnums';
import { isPasswordValid } from '../../../utils/validators';
import { UserModel } from '../../../models/account';

interface IProps {
  handleClose: () => void;
}

const UpdatePasswordForm: React.FunctionComponent<IProps> = ({ handleClose }) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [visibleOldPass, setVisibleOldPass] = useState(false);
  const [visibleNewPass, setVisibleNewPass] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

  const userData = state.user.data as UserModel;

  const changePassword = useMutation(ChangePasswordQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('myAccount.verificationPage.passwordChangedSuccessfully')
        }
      });
      handleClose();
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ChangePasswordQuery');
    }
  });

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setOldPassword(value);
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setNewPassword(value);
  };

  const toggleOldPasswordVisibility = () => {
    setVisibleOldPass(!visibleOldPass);
  };

  const toggleNewPasswordVisibility = () => {
    setVisibleNewPass(!visibleNewPass);
  };

  const handlePasswordValidityChange = (allValid: boolean) => {
    setIsNewPasswordValid(allValid);
  };

  const handleSave = () => {
    const model: ChangePasswordModel = {
      oldPassword: oldPassword,
      password: newPassword,
      confirmPassword: newPassword
    };
    if (!userData.isPasswordSetByUser) {
      if (isNewPasswordValid) {
        const model: ChangePasswordModel = {
          oldPassword: '',
          password: newPassword,
          confirmPassword: newPassword
        };
        changePassword.mutate(model);
      }
    }
    if (isNewPasswordValid && isPasswordValid(oldPassword)) {
      changePassword.mutate(model);
    }
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: 'mui-dialog' }}
    >
      <Box className="dialog__title-icon-container">
        <Box className="dialog__title-icon-container__title-box">
          {t('myAccount.accountPage.passwordPage.updatePasswordTitle')}
        </Box>
        <Box className="dialog__title-icon-container__close-icon-box">
          <CustomIconButton icon={<Close color="primary" />} onClick={() => handleClose()} />
        </Box>
      </Box>
      {userData.isPasswordSetByUser === true ? (
        <Box className="dialog__form-row-container">
          <CustomInputField
            id="oldPassword"
            value={oldPassword}
            placeholder={t('myAccount.accountPage.passwordPage.passPlaceholder') as string}
            type={visibleOldPass ? 'text' : 'password'}
            label={t('myAccount.accountPage.passwordPage.currentPassLabel') as string}
            onChange={handleOldPasswordChange}
            endIcon={
              <InputAdornment position="end">
                <IconButton onClick={toggleOldPasswordVisibility} edge="end">
                  {visibleOldPass ? (
                    <VisibilityOff className="visibility-icon" />
                  ) : (
                    <Visibility className="visibility-icon" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
      ) : null}
      <Box className="dialog__form-row-container">
        <CustomInputField
          id="newPassword"
          value={newPassword}
          placeholder={t('myAccount.accountPage.passwordPage.passPlaceholder') as string}
          type={visibleNewPass ? 'text' : 'password'}
          label={t('myAccount.accountPage.passwordPage.newPassLabel') as string}
          onChange={handleNewPasswordChange}
          endIcon={
            <InputAdornment position="end">
              <IconButton onClick={toggleNewPasswordVisibility} edge="end">
                {visibleNewPass ? (
                  <VisibilityOff className="visibility-icon" />
                ) : (
                  <Visibility className="visibility-icon" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      <Box className="dialog__form-row-container">
        <PasswordRequirements
          password={newPassword}
          onValidityChange={handlePasswordValidityChange}
        />
      </Box>
      <Box className="dialog__form-submit-button-container">
        <PrimaryButton
          text={t('myAccount.accountPage.passwordPage.actionButton')}
          onClick={() => handleSave()}
          disabled={
            !isNewPasswordValid || (userData.isPasswordSetByUser && !isPasswordValid(oldPassword))
          }
          additionalStyle={{
            '&.Mui-disabled': { background: 'rgba(255, 255, 255, 0.3)' },
            width: '210px',
            height: '42px'
          }}
        />
      </Box>
    </Dialog>
  );
};

export default UpdatePasswordForm;
