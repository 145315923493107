import { InitialStateType } from '../AppContext';
import { ActionType } from '../models/appcontext';
import { affiliateReducer } from './affiliateReducer';
import { balanceReducer } from './balanceReducer';
import { campaignPopupReducer } from './campaignPopupReducer';
import { confirmationReducer } from './confirmationReducer';
import { gamesReducer } from './gamesReducer';
import { loginPopupReducer } from './loginPopupReducer';
import { messageReducer } from './messageReducer';
import { myAccountReducer } from './myAccountReducer';
import { popupReducer } from './popupReducer';
import { userReducer } from './userReducer';
import { wheelPopupReducer } from './wheelPopupReducer';

export const mainReducer = (
  {
    user,
    message,
    routes,
    loginPopup,
    games,
    popup,
    wheelPopup,
    loadingBalance,
    affiliateData,
    myAccountMenuIndex,
    confirmation,
    campaignPopup
  }: InitialStateType,
  action: ActionType
) => ({
  user: userReducer(user, action),
  message: messageReducer(message, action),
  loginPopup: loginPopupReducer(loginPopup, action),
  popup: popupReducer(popup, action),
  routes,
  games: gamesReducer(games, action),
  wheelPopup: wheelPopupReducer(wheelPopup, action),
  loadingBalance: balanceReducer(loadingBalance, action),
  affiliateData: affiliateReducer(affiliateData, action),
  myAccountMenuIndex: myAccountReducer(myAccountMenuIndex, action),
  confirmation: confirmationReducer(confirmation, action),
  campaignPopup: campaignPopupReducer(campaignPopup, action)
});
