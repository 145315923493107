import React, { useState, useEffect, Fragment, useContext } from 'react';
import { AppContext } from '../../AppContext';
import { AppConsts } from '../../enums/AppConsts';
import { Actions } from '../../enums/ActionEnums';
import { useMutation, useQueryClient } from 'react-query';
import { Balance } from '../../queries/wallet';
import { decodeToken, handleError } from '../../utils/ui';
import queryString from 'query-string';

interface IProps {
  children: React.ReactNode;
}

const Auth: React.FunctionComponent<IProps> = ({ children }) => {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const { dispatch } = useContext(AppContext);
  const parsed = queryString.parse(location.search);
  const { order } = parsed;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(Balance, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.UpdateBalance,
        payload: data
      });
      dispatch({
        type: Actions.BalanceLoading,
        payload: false
      });
    },
    onError: ({ response }) => {
      dispatch({
        type: Actions.BalanceLoading,
        payload: true
      });
      handleError(response, dispatch);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Balance');
    }
  });

  useEffect(() => {
    const token = localStorage.getItem(AppConsts.AccessToken);
    if (!token) {
      // context remove user
      dispatch({ type: Actions.RemoveUser });
    } else {
      const decoded = decodeToken(token);
      const now = new Date().getTime() / 1000;
      if (decoded.exp < now) {
        // if is not valid -> context remove user
        dispatch({ type: Actions.RemoveUser });
      } else {
        dispatch({
          type: Actions.BalanceLoading,
          payload: true
        });
        mutate(order);
        dispatch({
          type: Actions.SetUser,
          payload: {
            data: {
              id: decoded.jti,
              accessToken: token,
              email: decoded.email,
              expirationDate: decoded.exp.toString(),
              firstName: decoded.name,
              lastName: decoded.family_name,
              roles: decoded.roles,
              currency: decoded.certpublickey,
              hasDeposited: decoded.hasDeposited ?? false,
              agreedEmailPromotions: decoded.agreedEmailPromotions ?? false,
              realBalance: 0,
              bonusBalance: 0,
              loyaltyPoints: 0,
              challengeLevel: null,
              verificationStatus: decoded.actort,
              favGames:
                decoded.groupsid && decoded.groupsid.length
                  ? decoded.groupsid.map((g) => parseInt(g))
                  : [],
              tags:
                decoded.primarygroupsid && decoded.primarygroupsid.length
                  ? decoded.primarygroupsid.map((g) => parseInt(g))
                  : []
            },
            token
          }
        });
      }
    }
    setWaitAuthCheck(false);
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default Auth;
