import React from 'react';
import { Box, InputLabel, TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Email } from '@mui/icons-material';
import { labelStyle } from '../SigninPage';
import { IErrors } from './SignupPageNew';
import { ILoginErrors } from '../SigninNew/SigninPageNew';
import { inputPropsStyle, getBorderColor, getIconColor } from '../../../utils/ui';

interface IProps {
  formErrors: IErrors | ILoginErrors;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  email: string;
}

const EmailForm: React.FunctionComponent<IProps> = ({
  formErrors,
  email,
  handleEmailChange
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <InputLabel htmlFor="email" style={labelStyle}>
        {t('account.emailLabel')}
      </InputLabel>
      <TextField
        id="email"
        name="email"
        margin="normal"
        variant="outlined"
        fullWidth
        required
        value={email}
        onChange={handleEmailChange}
        error={formErrors.email !== null}
        helperText={formErrors.email ? formErrors.email : ''}
        sx={{
          height: '46px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: getBorderColor(email)
            }
          }
        }}
        inputProps={{ sx: inputPropsStyle }}
        placeholder={t('account.emailPlaceHolder') as string}
        color="info"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email sx={{ fill: getIconColor(email, formErrors.email) }} />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default EmailForm;
