import { Box, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/theme';
import Label from '../../Common/Fields/Label';

interface IProps {
  id: string;
  value: string | number | moment.Moment | null;
  placeholder?: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg?: string | null;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: 'text' | 'number' | 'password';
  disabled?: boolean;
  className?: string;
  isOneDigit?: boolean;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

const CustomInputField = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      id,
      value,
      label,
      onChange,
      errorMsg,
      startIcon,
      endIcon,
      placeholder,
      type,
      disabled,
      className,
      isOneDigit = false,
      onPaste
    }: IProps,
    ref
  ) => {
    const darkBlueColor = '#25274A';

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      if (isOneDigit && !/^\d*$/.test(value)) {
        return; // Only allow digits
      }
      onChange(event);
    };

    return (
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {label && (
          <Label
            id={id}
            label={label}
            sx={{
              color: 'common.white'
            }}
          />
        )}
        <TextField
          className={className}
          id={id}
          name={id}
          type={type ?? 'text'}
          fullWidth
          value={value}
          onChange={handleInputChange}
          onPaste={onPaste}
          color="info"
          disabled={disabled ?? false}
          inputRef={ref} // Attach the forwarded ref here
          {...(errorMsg !== undefined && {
            required: true,
            error: errorMsg !== null,
            helperText: errorMsg ?? ''
          })}
          sx={{
            height: '54px',
            width: '100%',
            '& .MuiOutlinedInput-root': {
              backgroundColor: darkBlueColor,
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.15)!important'
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.15)!important'
              },
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.15)!important'
              }
            },
            '& input[type="password"]::-ms-reveal, input[type="password"]::-ms-clear, input[type="password"]::-webkit-clear-button, input[type="password"]::-webkit-credentials-auto-fill-button, input[type="password"]::-webkit-inner-spin-button, input[type="password"]::-webkit-outer-spin-button, input[type="password"]::-webkit-textfield-decoration-container':
              {
                display: 'none'
              },
            '& input:-webkit-autofill': {
              '-webkit-text-fill-color': '#FFFFFF !important',
              '-webkit-box-shadow': '0 0 0px 1000px #25274A inset !important',
              transition: 'background-color 5000s ease-in-out 0s !important'
            }
          }}
          inputProps={{
            maxLength: isOneDigit ? 1 : undefined,
            sx: {
              textAlign: isOneDigit ? 'center' : 'left',
              color: '#FFFFFF',
              height: '22px',
              fontSize: '16px',
              lineHeight: '22px',
              padding: '16px 20px',
              zIndex: 2,
              '&:-webkit-autofill': {
                transition: 'background-color 5000s ease-in-out 0s',
                WebkitTextFillColor: colors.info.main
              }
            }
          }}
          {...(placeholder && { placeholder })}
          InputProps={{
            startAdornment: startIcon ? (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ) : null,
            endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null
          }}
        />
      </Box>
    );
  }
);

CustomInputField.displayName = 'CustomInputField';

export default CustomInputField;
