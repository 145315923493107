import {
  EmailConfirmModel,
  EmailModel,
  LoginModel,
  LoginOtpModel,
  RegisterByPasswordModel,
  RegisterModel,
  RegisterOtpModel,
  ResetPasswordModel,
  VerifyLoginOtpModel,
  VerifyOtpModel
} from '../models/account';
import axios from 'axios';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const mailKey = process.env.MAIL_KEY || '';
const mailServiceEndpoint = process.env.MAIL_SERVICE_ENDPOINT || '';

export const RegisterQuery = async (data: RegisterModel) => {
  return await axios.post(`${baseUrl}/account/register`, data);
};

export const GenerateAndSendOtpQuery = async (data: RegisterOtpModel) => {
  return await axios.post(`${baseUrl}/account/GenerateAndSendOtp`, data);
};

export const GenerateAndSendOtpByLoginQuery = async (data: LoginOtpModel) => {
  return await axios.post(`${baseUrl}/account/GenerateAndSendOtpByLogin`, data);
};

export const VerifyOtpQuery = async (data: VerifyOtpModel) => {
  return await axios.post(`${baseUrl}/account/VerifyOtp`, data);
};

export const VerifyOtpLoginQuery = async (data: VerifyLoginOtpModel) => {
  return await axios.post(`${baseUrl}/account/VerifyOtpLogin`, data);
};

export const ResendOtpByLoginQuery = async (data: LoginOtpModel) => {
  return await axios.post(`${baseUrl}/account/ResendCodeByLogin`, data);
};

export const ResendOtpQuery = async (data: RegisterOtpModel) => {
  return await axios.post(`${baseUrl}/account/ResendCode`, data);
};

export const RegisterByPasswordQuery = async (data: RegisterByPasswordModel) => {
  return await axios.post(`${baseUrl}/account/RegisterByPassword`, data);
};

export const LoginQuery = async (data: LoginModel) => {
  return await axios.post(`${baseUrl}/account/login`, data);
};

export const ForgetPassQuery = async (email: string) => {
  const data = {
    email: email
  };
  return await axios.post(`${baseUrl}/account/ForgotPassword`, data);
};

export const ResetPassQuery = async (data: ResetPasswordModel) => {
  return await axios.post(`${baseUrl}/account/ResetPassword`, data);
};

export const ConfirmEmailQuery = async (data: EmailConfirmModel) => {
  return await axios.post(`${baseUrl}/account/ConfirmEmail`, data);
};

export const NewEmailConfirmQuery = async (email: string) => {
  return await axios.post(`${baseUrl}/account/NewEmailConfirm`, { email: email });
};

export const SendContactUsEmailQuery = async (email: EmailModel) => {
  return await axios.post(mailServiceEndpoint, email, {
    headers: {
      'x-functions-key': mailKey
    }
  });
};

export const UnsubscribeQuery = async (value: string) => {
  const data = {
    value
  };
  return await axios.post(`${baseUrl}/account/unsubscribe`, data);
};
