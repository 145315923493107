import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getProfileMenuItems } from '../../../utils/menu';
//* MUI
import { Badge, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
//* MUI Icons
import { PowerSettingsNew } from '@mui/icons-material';
//* Models
import { IBonus } from '../../../models/bonus';
//* Enums
import { AuthRoutes, PublicRoutes } from '../../../enums/RouteEnums';
import { Actions } from '../../../enums/ActionEnums';
import { AppConsts } from '../../../enums/AppConsts';
//* Styles
import './ProfileMenu.scss';

interface IProps {
  handleClose: () => void;
  suggestedBonuses: IBonus[];
  anchorElProfile: null | HTMLElement;
}

const ProfileMenu: React.FunctionComponent<IProps> = ({
  handleClose,
  anchorElProfile,
  suggestedBonuses
}: IProps) => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem(AppConsts.AccessToken);
    dispatch({
      type: Actions.RemoveUser
    });
    handleClose();
    navigate(PublicRoutes.Home);
    queryClient.invalidateQueries();
  };

  const menuItems = getProfileMenuItems(t);

  const handleClick = (index: number) => {
    dispatch({
      type: Actions.SetMyAccountMenuIndex,
      payload: index
    });

    handleClose();
    navigate(AuthRoutes.MyAccount);
  };

  return (
    <>
      <Badge
        color="error"
        className="profile-menu__badge"
        badgeContent={suggestedBonuses.length}
        invisible={suggestedBonuses.length === 0}
      />
      <Menu
        id="menu-profile"
        anchorEl={anchorElProfile}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        keepMounted
        open={Boolean(anchorElProfile)}
        onClose={handleClose}
        className="profile-menu"
      >
        {menuItems.map((item, index) => {
          const IconComponent = item.icon;

          return (
            <MenuItem
              className="profile-menu__item"
              key={`profile-menu-item-${index}`}
              onClick={() => handleClick(item.navIndex)}
            >
              <section>
                <ListItemIcon className="profile-menu__item-icon">
                  <IconComponent className="profile-menu__item-icon__item" />
                </ListItemIcon>
                <span className="profile-menu__item-text">{item.title}</span>
              </section>
              {item.navIndex === 2 && (
                <Badge
                  color="error"
                  className="profile-menu__item-badge"
                  badgeContent={suggestedBonuses.length}
                />
              )}
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem className="profile-menu__item" onClick={handleLogout}>
          <section>
            <ListItemIcon className="profile-menu__item-icon">
              <PowerSettingsNew className="profile-menu__item-icon__item" fontSize="small" />
            </ListItemIcon>
            <span className="profile-menu__item-text">{t('navigation.logout')}</span>
          </section>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
