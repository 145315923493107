import React, { useContext, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FilterOptionsState,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Theme
} from '@mui/material';
import { Transition } from '../../Common/Dialogs/DialogBox';
import { Close, Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getBorderColor, getIconColor, handleError } from '../../../utils/ui';
import './UserDetailsPopup.scss';
import { colors } from '../../../utils/theme';
import CSS from 'csstype';
import { countries, CountryType } from '../../../utils/countries';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { DepositDetailsModel } from '../../../models/profile';
import { SetUserDepositDetailsQuery } from '../../../queries/profile';
import { UserModel } from '../../../models/account';
import { currencies, CurrencyType } from '../../../utils/currencies';
import { AppConsts } from '../../../enums/AppConsts';
import Loader from '../../Common/Loader';
import { Query } from '../../../enums/RequestEnums';

interface UserDetailsPopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  makeDeposit: (hasUserSetDepositDetails: boolean) => void;
}

type IErrors = {
  firstName: string | null;
  lastName: string | null;
  phoneCode: string | null;
  phone: string | null;
  country: string | null;
  address: string | null;
  city: string | null;
  postCode: string | null;
};

const defaultErrorsObj: IErrors = {
  firstName: null,
  lastName: null,
  phoneCode: null,
  phone: null,
  country: null,
  address: null,
  city: null,
  postCode: null
};

export const labelStyle: CSS.Properties = {
  top: '10px',
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins'
};

export const inputPropsStyle = {
  height: '22px !important',
  padding: '12px !important',
  '&:-webkit-autofill': {
    WebkitTextFillColor: colors.info.main
  }
};

const filterOptions = (options: CountryType[], state: FilterOptionsState<CountryType>) => {
  let searchValue = state.inputValue;
  if (searchValue === '') {
    return options;
  } else if (searchValue.length > 0 && searchValue[0] === '+') {
    searchValue = state.inputValue.substring(1);
  }
  const result = options.filter(
    (opt) =>
      opt.label.toLowerCase().indexOf(searchValue.toLowerCase()) === 0 ||
      opt.phone.indexOf(searchValue) === 0
  );
  return result;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} style={{ width: 300 }} />;
};

const UserDetailsPopup: React.FC<UserDetailsPopupProps> = ({ isOpen, setIsOpen, makeDeposit }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [cookies] = useCookies(['country', 'language']);
  const queryClient = useQueryClient();
  const cookieCountryValue = cookies?.country;
  const cookieCountry = useMemo(() => {
    return countries.find(
      (c) => c.label.toLowerCase() === cookieCountryValue?.toLowerCase()
    ) as CountryType;
  }, [cookieCountryValue]);
  const [currency] = useState<CurrencyType | null>(
    cookieCountry?.label === 'United Kingdom' ? currencies[1] : currencies[0]
  );
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [phone, setPhone] = useState<string>('');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(cookieCountry || null);
  const [country, setCountry] = useState<CountryType | null>(cookieCountry || null);
  const [postCode, setPostCode] = useState('');

  const setUserDetails = useMutation(SetUserDepositDetailsQuery, {
    onSuccess: ({ data }) => {
      const updatedAccount = data.data as DepositDetailsModel;
      dispatch({
        type: Actions.SetUser,
        payload: {
          data: {
            ...(state.user.data as UserModel),
            firstName: updatedAccount.firstName,
            lastName: updatedAccount.lastName
          },
          token: data.accessToken as string
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      makeDeposit(true);
      setIsOpen(false);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries([Query.GetPlayerDetails]);
    }
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, city: t('errors.signup.city') });
    } else {
      setFormErrors({ ...formErrors, city: null });
    }
    setCity(value);
  };

  const handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, postCode: t('errors.signup.postCode') });
    } else {
      setFormErrors({ ...formErrors, postCode: null });
    }
    setPostCode(value);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, firstName: t('errors.signup.firstName') });
    } else {
      setFormErrors({ ...formErrors, firstName: null });
    }
    setFirstName(value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, lastName: t('errors.signup.lastName') });
    } else {
      setFormErrors({ ...formErrors, lastName: null });
    }
    setLastName(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, address: t('errors.signup.address') });
    } else {
      setFormErrors({ ...formErrors, address: null });
    }
    setAddress(value);
  };

  const handleCountryChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, country: t('errors.signup.country') });
    } else {
      setFormErrors({ ...formErrors, country: null });
    }
    setCountry(value);
  };

  const isFormValid = () => {
    return (
      firstName.length > 2 &&
      lastName.length > 2 &&
      address.length > 2 &&
      city.length > 2 &&
      postCode.length > 2 &&
      phone.length > 4 &&
      phoneCountry !== null
    );
  };

  const handleSubmit = () => {
    let hasErrors = false;
    const errors: IErrors = {
      firstName: null,
      lastName: null,
      phoneCode: null,
      phone: null,
      country: null,
      address: null,
      city: null,
      postCode: null
    };

    if (firstName.length < 2) {
      hasErrors = true;
      errors.firstName = t('errors.signup.firstName');
    }
    if (lastName.length < 2) {
      hasErrors = true;
      errors.lastName = t('errors.signup.lastName');
    }
    if (!phoneCountry) {
      hasErrors = true;
      errors.phoneCode = t('errors.signup.phoneCode');
    }
    if (phone.length < 4) {
      hasErrors = true;
      errors.phone = t('errors.signup.phone');
    }
    if (!country) {
      hasErrors = true;
      errors.country = t('errors.signup.country');
    }
    if (address.length < 2) {
      hasErrors = true;
      errors.address = t('errors.signup.address');
    }
    if (city.length < 2) {
      hasErrors = true;
      errors.city = t('errors.signup.city');
    }
    if (postCode.length < 2) {
      hasErrors = true;
      errors.postCode = t('errors.signup.postCode');
    }

    if (hasErrors) {
      setFormErrors(errors);
      console.log('Errors', errors);
    } else {
      const model: DepositDetailsModel = {
        firstName,
        lastName,
        country: (country?.label as string).replace(/\s/g, ''),
        phoneNumber: `+${phoneCountry?.phone as string}${phone}`,
        address,
        city,
        postCode,
        currency: currency?.code as string
      };
      console.log(model);
      setUserDetails.mutate(model);
    }
  };
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: 'mui-dialog-details-popup' }}
      onKeyDown={keyPress}
      onClose={handleClose}
    >
      <Box className="user-details-header">
        <Box className="user-details-header__title">{t('account.userDetailsPopupTitle')}</Box>
        <IconButton aria-label="close" onClick={handleClose} className="close-icon-button">
          <Close />
        </IconButton>
      </Box>
      <Box className="user-details-content-container">
        <Box className="user-details-content-container__content">
          <Box className="user-details-content-container__content__input">
            <InputLabel htmlFor="firstName" style={labelStyle}>
              {t('account.firstNameLabel')}
            </InputLabel>
            <TextField
              id="firstName"
              name="firstName"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={firstName}
              onChange={handleFirstNameChange}
              error={formErrors.firstName !== null}
              helperText={formErrors.firstName ? formErrors.firstName : ''}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: getBorderColor(firstName)
                  }
                }
              }}
              inputProps={{ sx: inputPropsStyle }}
              placeholder={t('account.namePlaceHolder') as string}
              color="info"
            />
          </Box>
          <Box className="user-details-content-container__content__input">
            <InputLabel htmlFor="lastName" style={labelStyle}>
              {t('account.lastNameLabel')}
            </InputLabel>
            <TextField
              id="lastName"
              name="lastName"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={lastName}
              onChange={handleLastNameChange}
              error={formErrors.lastName !== null}
              helperText={formErrors.lastName ? formErrors.lastName : ''}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: getBorderColor(lastName)
                  }
                }
              }}
              inputProps={{ sx: inputPropsStyle }}
              placeholder={t('account.namePlaceHolder') as string}
              color="info"
            />
          </Box>
        </Box>
        <Box>
          <InputLabel htmlFor="phone" style={labelStyle}>
            {t('account.phoneLabel')}
          </InputLabel>
          <TextField
            id="phone"
            name="phone"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            value={phone}
            onChange={handlePhoneChange}
            error={formErrors.phoneCode !== null}
            helperText={formErrors.phoneCode ? formErrors.phoneCode : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(phone)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.phonePlaceHolder') as string}
            color="info"
            InputProps={{
              startAdornment: (
                <Autocomplete
                  id="phoneCodeAutocomplate"
                  filterOptions={filterOptions}
                  style={{
                    position: 'relative',
                    height: 30,
                    top: '-24px',
                    left: '-14px',
                    width: '43%'
                  }}
                  disableClearable
                  PaperComponent={CustomPaper}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  value={phoneCountry}
                  onChange={handlePhoneCodeChange}
                  options={countries}
                  getOptionLabel={(option) => `+${option.phone}`}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        height: '46px',
                        '& > img': { mr: 2, flexShrink: 0 },
                        '& .MuiInputBase - input': {
                          height: '46px'
                        }
                      }}
                      {...props}
                      key={option.label}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt="countryFlag"
                      />
                      {option.label} +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="phoneCodeInput"
                      name="phoneCodeInput"
                      margin="normal"
                      variant="standard"
                      error={formErrors.phoneCode !== null}
                      helperText={formErrors.phoneCode ? formErrors.phoneCode : ''}
                      sx={{
                        borderRight: (t) => `1px solid ${t.palette.grey[50]}`,
                        height: '46px',
                        '& .MuiOutlinedInput-root': {
                          height: '46px',
                          padding: '0 !important',
                          '& fieldset': {
                            // borderColor: getBorderColor(country?.label)
                          }
                        }
                      }}
                      color="info"
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          ...inputPropsStyle,
                          textAlign: 'right',
                          marginRight: '3%',
                          color: (t: Theme) => t.palette.info.main
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true
                      }}
                    />
                  )}
                />
              )
            }}
          />
        </Box>
        <Box className="user-details-content-container__content__input">
          <InputLabel htmlFor="address" style={labelStyle}>
            {t('account.addressLabel') as string}
          </InputLabel>
          <TextField
            id="address"
            name="address"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            value={address}
            onChange={handleAddressChange}
            error={formErrors.address !== null}
            helperText={formErrors.address ? formErrors.address : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(address)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.addressPlaceHolder') as string}
            color="info"
          />
        </Box>
        <Box>
          <InputLabel htmlFor="countryAutocomplate" style={labelStyle}>
            {t('account.countryLabel')}
          </InputLabel>
          <Autocomplete
            id="countryAutocomplate"
            fullWidth
            value={country}
            onChange={handleCountryChange}
            options={countries}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  height: '46px',
                  '& > img': { mr: 2, flexShrink: 0 },
                  '& .MuiInputBase - input': {
                    height: '46px'
                  }
                }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt="countryFlag"
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                id="countryInput"
                name="countryInput"
                margin="normal"
                variant="outlined"
                error={formErrors.country !== null}
                helperText={formErrors.country ? formErrors.country : ''}
                sx={{
                  height: '46px',
                  '& .MuiOutlinedInput-root': {
                    height: '46px',
                    padding: '0 !important',
                    '& fieldset': {
                      borderColor: getBorderColor(country?.label)
                    }
                  }
                }}
                placeholder={t('account.countryPlaceHolder') as string}
                color="info"
                inputProps={{
                  ...params.inputProps,
                  sx: inputPropsStyle
                  // autoComplete: 'new-password' // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start" style={{ paddingLeft: '13px' }}>
                      <Language sx={{ fill: getIconColor(country?.label, formErrors.country) }} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Box>
        <Box className="user-details-content-container__content__input">
          <InputLabel htmlFor="city" style={labelStyle}>
            {t('account.cityLabel') as string}
          </InputLabel>
          <TextField
            id="city"
            name="city"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            value={city}
            onChange={handleCityChange}
            error={formErrors.city !== null}
            helperText={formErrors.city ? formErrors.city : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(city)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.cityPlaceHolder') as string}
            color="info"
          />
        </Box>
        <Box className="user-details-content-container__content__input">
          <InputLabel htmlFor="postCode" style={labelStyle}>
            {t('account.postCodeLabel') as string}
          </InputLabel>
          <TextField
            id="postCode"
            name="postCode"
            margin="normal"
            variant="outlined"
            fullWidth
            required
            value={postCode}
            onChange={handlePostCodeChange}
            error={formErrors.postCode !== null}
            helperText={formErrors.postCode ? formErrors.postCode : ''}
            sx={{
              height: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(postCode)
                }
              }
            }}
            inputProps={{ sx: inputPropsStyle }}
            placeholder={t('account.postCodePlaceHolder') as string}
            color="info"
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isFormValid()}
            className="continue-button-container__custom-continue-button"
            onClick={handleSubmit}
          >
            {t('account.userDetailsPopupSubmitButton')}
          </Button>
        </Box>
      </Box>
      <Loader loading={setUserDetails.isLoading} />
    </Dialog>
  );
};

export default UserDetailsPopup;
