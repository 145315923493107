import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AppProvider, AppContext } from '../AppContext';
import { PublicRoutes, RouteNames } from '../enums/RouteEnums';
import { buildTheme } from '../utils/theme';
import Navigation from './Navigation/Navigation';
import Message from './Common/Message';
import Auth from './Common/Auth';
import Footer from './Footer/Footer';
import LayoutPage from './Common/LayoutPage';
import FooterPage from './Footer/FooterPage';
import ScrollRestoration from '../utils/ui';
import AuthRoute from './Common/AuthRoute';
import Popup from './Common/Popup';
import Wheel from './Common/Wheel';
import Locator from './Common/Locator';
import Confirmation from './Common/Confirmation/Confirmation';
import fav from '../assets/fav.png';
import favClone from '../assets/logo_clone.svg';
import CampaignPopup from './Common/CampaignPopup/CampaignPopup';
import LoginPopupNew from './Common/LoginPopupNew';

const App: React.FunctionComponent = () => {
  const { state } = useContext(AppContext);
  const isClone = process.env.ENVIRONMENT === 'licence';

  const docTitle = isClone ? 'Slots Sharks' : 'Bubbles Bet';
  document.title = docTitle;

  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = isClone ? favClone : fav;

  // Remove the old favicon and add the new one
  document.head.appendChild(link);

  return (
    <ThemeProvider theme={buildTheme()}>
      <AppProvider>
        <Locator>
          <Auth>
            <Router>
              <Navigation />
              <LayoutPage>
                <ScrollRestoration />
                <Routes>
                  {state.routes.map((r, index) => (
                    <Route
                      key={index}
                      path={r.path}
                      element={<AuthRoute isPublic={r.isPublic} Component={r.Component} />}
                    />
                  ))}
                  <Route
                    path={PublicRoutes.TermsAndCoditions}
                    element={<FooterPage title={RouteNames.TermsAndCoditions} />}
                  />
                  <Route
                    path={PublicRoutes.PrivacyPolicy}
                    element={<FooterPage title={RouteNames.PrivacyPolicy} />}
                  />
                  <Route
                    path={PublicRoutes.BonusTerms}
                    element={<FooterPage title={RouteNames.BonusTerms} />}
                  />
                  <Route
                    path={PublicRoutes.ResponsibleGaming}
                    element={<FooterPage title={RouteNames.ResponsibleGaming} />}
                  />
                  <Route
                    path={PublicRoutes.AboutUs}
                    element={<FooterPage title={RouteNames.AboutUs} />}
                  />
                  <Route
                    path={PublicRoutes.HelpCenter}
                    element={<FooterPage title={RouteNames.HelpCenter} />}
                  />
                  <Route
                    path={PublicRoutes.Affiliates}
                    element={<FooterPage title={RouteNames.Affiliates} />}
                  />
                  {isClone && (
                    <Route
                      path={PublicRoutes.KYC}
                      element={<FooterPage title={RouteNames.KYC} />}
                    />
                  )}
                  <Route path="*" element={<Navigate to={PublicRoutes.NotFound} />} />
                </Routes>
                <Wheel />
                <Message />
                <LoginPopupNew />
                <Popup />
                <CampaignPopup />
                <Confirmation />
              </LayoutPage>
              <Footer />
            </Router>
          </Auth>
        </Locator>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;
