import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { countries } from '../../utils/countries';
import AccessDenied from './AccessDenied/AccessDenied';
import { useCookies } from 'react-cookie';

interface IProps {
  children: React.ReactNode;
}

const restrictedCountriesBubbles = ['Bulgaria'];
const restrictedCountriesLicense = [
  'Bulgaria',
  'United Kingdom',
  'United States of America',
  'Australia',
  'Austria',
  'France',
  'Germany',
  'Netherlands',
  'Spain'
];
const FATFBlacklist = [
  'KP', // North Korea
  'IR', // Iran,
  'MM' // Myanmar
];
const Locator: React.FunctionComponent<IProps> = ({ children }: IProps) => {
  const [cookies, setCookie] = useCookies(['language', 'country']);
  const [userCountry, setUserCountry] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const isDev = process.env.ENVIRONMENT === 'develop';
  const isClone = process.env.ENVIRONMENT === 'licence';
  const restrictedCountries = isClone ? restrictedCountriesLicense : restrictedCountriesBubbles;

  useEffect(() => {
    const checkAndRedirect = async () => {
      try {
        if (!userCountry || userCountry.length === 0) {
          const response = await axios.get('https://ipapi.co/json/');
          const { data } = response;
          const country = countries.find((c) => c.code === data.country)?.label ?? '';
          const blacklisted = FATFBlacklist.includes(data.country);
          setIsBlacklisted(blacklisted);
          setUserCountry(country);
          setCookie('country', country, { path: '/', maxAge: 31536000 });
        }
      } catch (error) {
        console.error('Error fetching geo info:', error);
      }
    };

    checkAndRedirect();
  }, []);

  const shouldRestrict = () => {
    if (userCountry.length > 0 && !cookies.language) {
      setLanguageByLocation(userCountry);
    }
    return (
      !isDev &&
      userCountry.length > 0 &&
      (restrictedCountries.includes(userCountry) || isBlacklisted)
    );
  };

  const setLanguageByLocation = (userCountry: string) => {
    let languageCode = 'en';
    if (userCountry === 'France') {
      languageCode = 'fr';
    } else if (userCountry === 'Germany') {
      languageCode = 'de';
    } else if (userCountry === 'Spain') {
      languageCode = 'es';
    } else if (userCountry === 'Portugal') {
      languageCode = 'pt';
    } else if (userCountry === 'Russia') {
      languageCode = 'ru';
    }
    setCookie('language', languageCode, { path: '/', maxAge: 31536000 });
  };
  return <Fragment>{shouldRestrict() ? <AccessDenied /> : children}</Fragment>;
};

export default Locator;
