import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField
} from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { Lock, VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes, PublicRoutes } from '../../../enums/RouteEnums';
import { useTranslation } from 'react-i18next';
import './SigninPageNew.scss';
import { isValidEmail } from '../../../utils/validators';
import { labelStyle } from '../SigninPage';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { countries, CountryType } from '../../../utils/countries';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../../AppContext';
import { handleError, inputPropsStyle, getBorderColor, getIconColor } from '../../../utils/ui';
import { LoginModel, LoginOtpModel, VerifyLoginOtpModel } from '../../../models/account';
import { Actions } from '../../../enums/ActionEnums';
import {
  GenerateAndSendOtpByLoginQuery,
  LoginQuery,
  ResendOtpByLoginQuery,
  VerifyOtpLoginQuery
} from '../../../queries/account';
import { AppConsts } from '../../../enums/AppConsts';
import Loader from '../../Common/Loader';
import EmailForm from '../SignupNew/EmailForm';
import PhoneForm from '../SignupNew/PhoneForm';
import OtpForm from '../SignupNew/OtpForm';
import ResetPasswordPage from '../ForgerPasswordPage';
import { useCookies } from 'react-cookie';
import Logo from './Logo';
import Header from './Header';

export type ILoginErrors = {
  email: string | null;
  phone: string | null;
  username: string | null;
  password: string | null;
  phoneCode: string | null;
};

const defaultErrorsObj: ILoginErrors = {
  email: null,
  phone: null,
  username: null,
  password: null,
  phoneCode: null
};

const SigninPageNew: React.FC = () => {
  const [cookies] = useCookies(['country']);
  const country = cookies?.country;
  const userCountry = useMemo(() => {
    return countries.find((c) => c.label.toLowerCase() === country?.toLowerCase()) as CountryType;
  }, [country]);

  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [visiblePass, setVisiblePass] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState<string>('');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(userCountry || null);
  const [password, setPassword] = useState<string>('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [openOtpForm, setOpenOtpForm] = useState(false);

  const generateAndSendOtp = useMutation(GenerateAndSendOtpByLoginQuery, {
    onSuccess: () => {
      setOpenOtpForm(true);
      setDisableResend(true);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const resetFormFields = () => {
    setEmail('');
    setUsername('');
    setPhone('');
    setPassword('');
    setFormErrors(defaultErrorsObj);
  };

  const handleLoginOptionChange = (newPage: number) => {
    resetFormFields();
    setPage(newPage);
  };

  const verifyOtp = useMutation(VerifyOtpLoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      setOpenOtpForm(false);
      navigate(AuthRoutes.MyAccount);
    },
    onError: ({ response }) => {
      if (response?.data?.error === 'Verification code has expired. Please request a new one.') {
        setOpenOtpForm(false);
      }
      handleError(response, dispatch);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  const loginByUserAndPass = useMutation(LoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      navigate(AuthRoutes.MyAccount);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  const resendOtp = useMutation(ResendOtpByLoginQuery, {
    onError: ({ response }) => handleError(response, dispatch)
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.signup.email') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.shortUsername') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, password: t('errors.shortPass') });
    } else {
      setFormErrors({ ...formErrors, password: null });
    }
    setPassword(value);
  };

  const isValidState = () => {
    if (page === 0) {
      return isEmailFormValid();
    } else if (page === 1) {
      return isPhoneFormValid();
    } else if (page === 2) {
      return isPasswordFormValid();
    }
  };

  const isEmailFormValid = () => {
    return isValidEmail(email);
  };

  const isPhoneFormValid = () => {
    return phone.length >= 4 && phoneCountry !== null;
  };

  const isPasswordFormValid = () => {
    return username.length > 3 && password.length > 3;
  };

  const getRegisterOtpModel = async () => {
    const model = {
      email,
      phoneNumber: phone.length < 2 ? '' : `+${phoneCountry?.phone as string}${phone}`,
      username
    };
    return model;
  };

  const handleOtpSubmit = async (value: number) => {
    const model = await getRegisterOtpModel();
    const verifyModel: VerifyLoginOtpModel = {
      ...model,
      otp: value
    };
    verifyOtp.mutate(verifyModel);
  };

  const handleOtpResendSubmit = async () => {
    const model = await getRegisterOtpModel();
    resendOtp.mutate(model);
  };

  const handleSubmit = async () => {
    if (page !== 2) {
      const model: LoginOtpModel = {
        email,
        phoneNumber: phone.length < 2 ? '' : `+${phoneCountry?.phone as string}${phone}`
      };
      generateAndSendOtp.mutate(model);
    } else {
      const model: LoginModel = {
        password: password,
        username: username
      };
      loginByUserAndPass.mutate(model);
    }
  };

  const getInfoText = (page: number) => {
    if (page === 0) {
      return t('account.loginEmailInfoText');
    } else if (page === 1) {
      return t('account.loginPhoneInfoText');
    } else if (page === 2) {
      return t('account.loginUserPassInfoText');
    }
  };

  return (
    <Box className="main-login">
      <Box className="main-login__content-box">
        <Logo />
        <Box className="main-login__content-box__inner-content">
          <Box className="main-login__content-box__inner-content__container">
            <Header
              handleOptionChange={handleLoginOptionChange}
              page={page}
              titleText={t('account.signIn') as string}
              subtitleText={t('account.signInSubtitle') as string}
            />
            <Box className="main-login__content-box__inner-content__container__info-text-container">
              {getInfoText(page)}
            </Box>
            <Box className="main-login__content-box__inner-content__container__email-inputs-container">
              {page === 2 && (
                <Box>
                  <InputLabel htmlFor="username" style={labelStyle}>
                    {t('account.username')}
                  </InputLabel>
                  <TextField
                    id="username"
                    name="username"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                    value={username}
                    onChange={handleUsernameChange}
                    error={formErrors.username !== null}
                    helperText={formErrors.username ? formErrors.username : ''}
                    sx={{
                      height: '46px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: getBorderColor(username)
                        }
                      }
                    }}
                    inputProps={{ sx: inputPropsStyle }}
                    placeholder={t('account.usernamePlaceHolder') as string}
                    color="info"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle
                            sx={{ fill: getIconColor(username, formErrors.username) }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              )}
              {page === 0 && (
                <EmailForm
                  formErrors={formErrors}
                  email={email}
                  handleEmailChange={handleEmailChange}
                />
              )}
              {page === 1 && (
                <PhoneForm
                  formErrors={formErrors}
                  phone={phone}
                  phoneCountry={phoneCountry}
                  handlePhoneCodeChange={handlePhoneCodeChange}
                  handlePhoneChange={handlePhoneChange}
                />
              )}
              {page === 2 && (
                <Box>
                  <InputLabel htmlFor="password" style={labelStyle}>
                    {t('account.password')}
                  </InputLabel>
                  {/* <Tooltip title={t('account.passwordRequirements') as string} placement="top"> */}
                  <TextField
                    id="password"
                    name="password"
                    margin="normal"
                    variant="outlined"
                    type={visiblePass ? 'text' : 'password'}
                    fullWidth
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    error={formErrors.password !== null}
                    helperText={formErrors.password ? formErrors.password : ''}
                    sx={{
                      height: '46px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: getBorderColor(password)
                        }
                      }
                    }}
                    inputProps={{ sx: inputPropsStyle }}
                    placeholder={t('account.passPlaceholder') as string}
                    color="info"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock sx={{ fill: getIconColor(password, formErrors.password) }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisiblePass(!visiblePass)}
                          >
                            {visiblePass ? (
                              <VisibilityOff
                                sx={{ fill: getIconColor(password, formErrors.password) }}
                              />
                            ) : (
                              <Visibility
                                sx={{ fill: getIconColor(password, formErrors.password) }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {/* </Tooltip> */}
                </Box>
              )}
            </Box>
            {page === 2 && (
              <Box className="main-login__content-box__inner-content__container__check-box-container">
                <Box>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="info" />}
                    label={t('account.remember') as string}
                    className="check-box-remember"
                  />
                </Box>
                <Box>
                  <Link
                    onClick={() => setOpenResetPass(true)}
                    variant="body2"
                    color="info.main"
                    className="forgot-password-link-button"
                  >
                    {t('account.forgotPass')}
                  </Link>
                </Box>
              </Box>
            )}
            <Box className="main-login__content-box__inner-content__container__signin-button-container">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isValidState() || generateAndSendOtp.isLoading}
                className="main-login__content-box__inner-content__container__signin-button-container__custom-signin-button"
                onClick={handleSubmit}
              >
                {t('account.signIn')}
              </Button>
            </Box>
            <Box className="main-login__content-box__inner-content__container__create-an-account">
              <Box className="main-login__content-box__inner-content__container__create-an-account__divider-span-5">
                <Divider />
              </Box>
              <Box>
                <Box className="main-login__content-box__inner-content__container__create-an-account__or">
                  {t('common.or')}
                </Box>
              </Box>
              <Box className="main-login__content-box__inner-content__container__create-an-account__divider-span-6">
                <Divider />
              </Box>
            </Box>
            <Box className="main-login__content-box__inner-content__container__dont-have-account-text">
              {t('account.noAccount')}
            </Box>
            <Box className="main-login__content-box__inner-content__container__create-account-button-container">
              <Button
                className="main-login__content-box__inner-content__container__create-account-button-container__custom-signin-button"
                fullWidth
                variant="outlined"
                disabled={
                  generateAndSendOtp.isLoading ||
                  verifyOtp.isLoading ||
                  loginByUserAndPass.isLoading
                }
                onClick={() => navigate(PublicRoutes.Signup)}
              >
                {t('account.createAccount')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="main-login__background-box" />
      {openOtpForm && (
        <OtpForm
          handleClose={() => setOpenOtpForm(false)}
          onSubmit={handleOtpSubmit}
          onResendSubmit={handleOtpResendSubmit}
          otpByEmail={page === 0}
          disableResend={disableResend}
          isLogin={true}
        />
      )}
      {openResetPass && <ResetPasswordPage handleClose={() => setOpenResetPass(false)} />}
      <Loader
        loading={
          generateAndSendOtp.isLoading || verifyOtp.isLoading || loginByUserAndPass.isLoading
        }
      />
    </Box>
  );
};

export default SigninPageNew;
